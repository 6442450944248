import React, { useState, useEffect } from "react";
import Item from "./Item";
import { useDrop } from "react-dnd";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { LuArchiveRestore } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const Section = ({
  id,
  name,
  items,
  onItemDrop,
  onItemRemove,
  onItemCheckedChange,
  updateItems,
  moveItem,
  submitProcessNameChange,
  onEditProduct,
  onDeleteProduct,
  onUnarchiveProduct,
  product,
  isArchived,
  onCollectSerialChange, // pass the new callback
}) => {
  const [isOver, setIsOver] = useState(false);

  const [, drop] = useDrop({
    accept: "item",
    drop: (item, monitor) => {
      onItemDrop(id, item.id);
      setIsOver(false); // Reset on drop
    },
    hover: () => {
      if (!isOver) setIsOver(true);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  });

  const moveItemUp = (index) => {
    if (index === 0) return;
    moveItem(id, index, index - 1);
  };

  const moveItemDown = (index) => {
    if (index === items.length - 1) return;
    moveItem(id, index, index + 1);
  };
  const navigate = useNavigate();

  const viewMasterSerials = () => {
    navigate("/masterSerials", { state: { product: product } });
  };

  const sectionStyle = isOver ? { backgroundColor: "green", opacity: 0.5 } : {};

  return (
    <div ref={drop} className="section" style={sectionStyle}>
      <div className="section-header">
        <h2>{name}</h2>
        <button
          onClick={viewMasterSerials}
          className="view-master-serials-button button"
        >
          View Master Serials
        </button>
        <button
          className="button edit-product-button"
          onClick={() => onEditProduct(product)}
        >
          <AiOutlineEdit className="icon" />
          Edit Product
        </button>
        {isArchived ? (
          <button
            className="button unarchive-product-button"
            onClick={() => onUnarchiveProduct(product)}
          >
            <LuArchiveRestore className="icon" />
            Unarchive Product
          </button>
        ) : (
          <button
            className="button delete-product-button secondary"
            onClick={() => onDeleteProduct(product)}
          >
            <AiOutlineDelete className="icon" />
            Delete Product
          </button>
        )}
      </div>
      <div className="section-items">
        <div className="start-placeholder">Start</div>
        {items.map((item, index) => (
          <Item
            key={item.id}
            id={item.id}
            process={item.process}
            onRemove={() => onItemRemove(id, item.id)}
            index={index}
            sectionId={id}
            verify_prev={item.verify_prev}
            onCheckedChange={onItemCheckedChange}
            moveItemUp={() => moveItemUp(index)}
            moveItemDown={() => moveItemDown(index)}
            submitProcessNameChange={submitProcessNameChange}
            isInSection={true}
            moveItem={moveItem}
            totalItems={items.length}
            collect_serial={item.collect_serial} // pass the new prop
            onCollectSerialChange={onCollectSerialChange} // pass the new callback
          />
        ))}
        <div className="end-placeholder">End</div>
      </div>
    </div>
  );
};

export default Section;
