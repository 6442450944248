import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import {
  Table,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  Tag,
  Tooltip,
} from "antd";
import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import AddRecordModal from "../models/AddRecordModal";
import { useAuth } from "../contexts/AuthContext";
import "../records.css";
import { SERVER_URL } from "../utils/constants";
import { saveAs } from "file-saver";
import useAlert from "../hooks/useAlert";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import debounce from "lodash/debounce";

const { Option } = Select;
const { RangePicker } = DatePicker;

// Utility function to log messages only if debug=1 is in the URL
const debugLog = (...args) => {
  const params = new URLSearchParams(window.location.search);
  if (params.get("debug") === "1") {
    console.log(...args);
  }
};

// Example usage of debugLog
debugLog("Debugging enabled");

const Records = () => {
  const { user, logout } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [tableState, setTableState] = useState("live");
  const [activeFilters, setActiveFilters] = useState({});
  const [sortColumn, setSortColumn] = useState("test_id");
  const [sortDirection, setSortDirection] = useState("desc");
  const showAlert = useAlert();
  const location = useLocation();

  const parseQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const filters = {};
    params.forEach((value, key) => {
      if (key !== "debug") {
        // Exclude the debug parameter
        filters[key] = value;
      }
    });
    setActiveFilters(filters);
  };

  const resultFilterOptions = [
    { text: "PASS", value: "PASS" },
    { text: "FAIL", value: "FAIL" },
    { text: "RESOLVED", value: "RESOLVED" },
  ];

  const stateRef = useRef({
    currentPage,
    pageSize,
    sortColumn,
    sortDirection,
    activeFilters,
    tableState,
  });

  const updateStateRef = (updatedState) => {
    stateRef.current = { ...stateRef.current, ...updatedState };
  };

  const fetchData = useCallback(async () => {
    debugLog("fetchData called with filters:", stateRef.current.activeFilters);
    try {
      const filters = JSON.stringify(stateRef.current.activeFilters);

      const result = await axios.get(`${SERVER_URL}/HC/api/getRecords.php`, {
        headers: {
          Authorization: user.token,
          role: user.role,
          userId: user.userId,
          tableState: tableState,
          PrivateCode: user.privateCode,
          Page: stateRef.current.currentPage,
          PageSize: stateRef.current.pageSize,
          Filters: filters,
          SortColumn: stateRef.current.sortColumn,
          SortDirection: stateRef.current.sortDirection,
        },
      });

      setData(result.data.data || []);
      setTotalRecords(result.data.total || 0);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
      } else {
        debugLog("Error fetching data:", error);
      }
    }
  }, [
    tableState,
    user.token,
    user.role,
    user.userId,
    user.privateCode,
    logout,
  ]);

  const debounceFetchData = useCallback(
    debounce(() => {
      fetchData();
    }, 300),
    [fetchData]
  );

  // Parse filters from URL when component mounts
  useEffect(() => {
    parseQueryParams();
  }, []);

  // Watch activeFilters to trigger data fetch
  useEffect(() => {
    stateRef.current.activeFilters = activeFilters;
    debounceFetchData();
  }, [activeFilters]);

  useEffect(() => {
    debugLog("useEffect triggered with dependencies:", {
      currentPage,
      pageSize,
      sortColumn,
      sortDirection,
      activeFilters,
      tableState,
    });

    // Update the ref with the latest state
    updateStateRef({
      currentPage,
      pageSize,
      sortColumn,
      sortDirection,
      activeFilters,
      tableState,
    });

    debounceFetchData();
  }, [
    currentPage,
    pageSize,
    sortColumn,
    sortDirection,
    activeFilters,
    tableState,
  ]);

  useEffect(() => {
    debugLog("useEffect triggered with tableState:", tableState);

    // Update the ref with the latest tableState
    updateStateRef({ tableState });

    // Fetch data when tableState changes
    debounceFetchData();
  }, [tableState]);

  const handleProductClick = (product) => {
    debugLog("handleProductClick called with product:", product);
    const newFilters = { product };
    setActiveFilters((prev) => ({ ...prev, ...newFilters }));
    setCurrentPage(1); // Reset page number to 1
    debounceFetchData();
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    debugLog(
      "handleSearch called with selectedKeys:",
      selectedKeys,
      "dataIndex:",
      dataIndex
    );
    confirm();
    setActiveFilters((prev) => ({ ...prev, [dataIndex]: selectedKeys[0] }));
    setCurrentPage(1); // Reset page number to 1
    debounceFetchData();
  };

  const handleReset = (clearFilters, dataIndex) => {
    debugLog("handleReset called with dataIndex:", dataIndex);
    clearFilters();
    setActiveFilters((prev) => ({ ...prev, [dataIndex]: "" }));
    setCurrentPage(1); // Reset page number to 1
    debounceFetchData();
  };

  const handleRecordAdded = () => {
    debugLog("handleRecordAdded called");
    setShowModal(false);
    debounceFetchData();
  };

  const convertToCSV = (objArray) => {
    debugLog("convertToCSV called");
    const array = Array.isArray(objArray) ? objArray : JSON.parse(objArray);
    let str = "";

    if (array.length === 0) {
      console.warn("convertToCSV: No data available for export.");
      return "";
    }

    const headerList = Object.keys(array[0] || {});
    const headerString = headerList.join(",") + "\r\n";
    str += headerString;

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        let value = array[i][index];

        // Handle null and undefined values
        if (value === null || value === undefined) {
          value = ""; // Replace null/undefined with an empty string
        } else {
          value = value.toString().replace(/,/g, " "); // Remove commas to prevent CSV formatting issues
        }

        line += `"${value}"`; // Wrap values in quotes to preserve formatting
      }
      str += line + "\r\n";
    }

    return str;
  };

  const fetchAllRecords = async () => {
    debugLog("fetchAllRecords called");
    try {
      const filters = JSON.stringify(stateRef.current.activeFilters);

      const result = await axios.get(`${SERVER_URL}/HC/api/getRecords.php`, {
        headers: {
          Authorization: user.token,
          role: user.role,
          userId: user.userId,
          tableState: tableState,
          PrivateCode: user.privateCode,
          Page: 1,
          PageSize: totalRecords, // Fetch all records
          Filters: filters,
          SortColumn: stateRef.current.sortColumn,
          SortDirection: stateRef.current.sortDirection,
        },
      });

      return result.data.data || [];
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
      } else {
        debugLog("Error fetching all records:", error);
      }
      return [];
    }
  };

  const exportCSVFile = async () => {
    debugLog("exportCSVFile called");
    const allRecords = await fetchAllRecords();
    const csvStr = convertToCSV(allRecords);
    const blob = new Blob([csvStr], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "records.csv");
  };

  const handleTableChange = (pagination, filters, sorter) => {
    debugLog(
      "handleTableChange called with pagination:",
      pagination,
      "sorter:",
      sorter,
      "filters:",
      filters
    );

    const newSortColumn = sorter.columnKey || "test_id";
    const newSortDirection = sorter.order === "ascend" ? "asc" : "desc";
    const newCurrentPage = pagination.current;
    const newPageSize = pagination.pageSize;

    const updatedFilters = {
      ...activeFilters,
      result: filters.result ? filters.result[0] : undefined,
      date_entered: filters.date_entered ? filters.date_entered : undefined,
    };

    // Batch state updates and update the ref
    updateStateRef({
      currentPage: newCurrentPage,
      pageSize: newPageSize,
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
      activeFilters: updatedFilters,
    });

    setSortColumn(newSortColumn);
    setSortDirection(newSortDirection);
    setCurrentPage(newCurrentPage);
    setPageSize(newPageSize);
    setActiveFilters(updatedFilters);

    // Call the debounced function once with the updated state
    debounceFetchData();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const [expandedRows, setExpandedRows] = useState({});

  const toggleExpand = (key) => {
    setExpandedRows((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const getColumnDateFilterProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          onChange={(dates) => {
            setSelectedKeys(dates ? [dates] : []);
          }}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => {
            confirm();
            const dateFilter =
              selectedKeys.length > 0 ? selectedKeys[0] : undefined;
            setActiveFilters((prev) => ({ ...prev, [dataIndex]: dateFilter }));
            setCurrentPage(1); // Reset page number to 1
            debounceFetchData();
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            setActiveFilters((prev) => ({ ...prev, [dataIndex]: undefined }));
            setCurrentPage(1); // Reset page number to 1
            debounceFetchData();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <CalendarOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const columns = [
    {
      title: "Test ID",
      dataIndex: "test_id",
      key: "test_id",
      sorter: (a, b) => a.test_id - b.test_id,
      ...getColumnSearchProps("test_id"),
    },
    {
      title: "Master Serial",
      dataIndex: "master_serial",
      key: "master_serial",
      sorter: (a, b) => a.master_serial.localeCompare(b.master_serial),
      ...getColumnSearchProps("master_serial"),
    },
    {
      title: "Serial",
      dataIndex: "serial",
      key: "serial",
      sorter: (a, b) => a.serial.localeCompare(b.serial),
      ...getColumnSearchProps("serial"),
      render: (text, record) => {
        const isTruncated = text.length > 50;
        const isExpanded = expandedRows[record.test_id] || false;

        return (
          <div>
            {isExpanded || !isTruncated ? (
              <span>{text}</span>
            ) : (
              <span>{text.slice(0, 50)}...</span>
            )}
            {isTruncated && (
              <Button
                type="link"
                onClick={() => toggleExpand(record.test_id)}
                style={{ padding: 0 }}
              >
                {isExpanded ? "Collapse" : "Expand"}
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: "Work Order",
      dataIndex: "workorder",
      key: "workorder",
      sorter: (a, b) => a.workorder.localeCompare(b.workorder),
      ...getColumnSearchProps("workorder"),
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      sorter: (a, b) => a.product.localeCompare(b.product),
      ...getColumnSearchProps("product"),
      render: (text) => (
        <a href="#" onClick={() => handleProductClick(text)}>
          {text}
        </a>
      ),
    },
    {
      title: "Operator Name",
      dataIndex: "operatorName",
      key: "operatorName",
      sorter: (a, b) => a.operatorName.localeCompare(b.operatorName),
      ...getColumnSearchProps("operatorName"),
    },
    {
      title: "Process",
      dataIndex: "process",
      key: "process",
      sorter: (a, b) => a.process.localeCompare(b.process),
      ...getColumnSearchProps("process"),
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "result",
      filters: resultFilterOptions,
      onFilter: (value, record) => record.result.includes(value),
    },
    {
      title: "Fail Detail",
      dataIndex: "fail_detail",
      key: "fail_detail",
      sorter: (a, b) => a.fail_detail.localeCompare(b.fail_detail),
      ...getColumnSearchProps("fail_detail"),
    },
    {
      title: "Date Entered",
      dataIndex: "date_entered",
      key: "date_entered",
      sorter: (a, b) => new Date(a.date_entered) - new Date(b.date_entered),
      ...getColumnDateFilterProps("date_entered"),
    },
    {
      title: "Additional Serials",
      dataIndex: "childSerialsInfo",
      key: "childSerialsInfo",
      render: (childSerialsInfo) => {
        if (!childSerialsInfo || childSerialsInfo === "N/A") {
          return <Tag color="default">No attached serials</Tag>;
        }

        const childSerials = childSerialsInfo.split("|").map((item) => {
          const [childType, serial] = item.split(": ");
          return (
            <Tooltip title={childType} key={serial}>
              <Tag color="blue" style={{ marginBottom: "4px" }}>
                {serial}
              </Tag>
            </Tooltip>
          );
        });

        return <div>{childSerials}</div>;
      },
    },
  ];

  if (user.role === "Admin") {
    columns.push({
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="button-delete"
            onClick={() => handleDelete(record)}
            type="danger"
          >
            Delete
          </Button>
        </Space>
      ),
    });
  }

  const handleDelete = (record) => {
    showAlert(
      true,
      "Confirm Delete",
      `Are you sure you want to delete this record for serial: ${record.serial}?`,
      (confirmed) => {
        if (confirmed) {
          deleteRecord(record);
        }
      },
      "confirm"
    );
  };

  const deleteRecord = async (record) => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/HC/api/deleteRecord.php`,
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
          data: {
            recordId: record.test_id,
          },
        }
      );

      if (response.data.success) {
        toast.success("Record deleted successfully");
        debounceFetchData();
      } else {
        toast.error("Failed to delete record");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
      }
      toast.error("Error deleting record");
    }
  };

  return (
    <div className="records">
      {!showModal && (
        <>
          <Select
            defaultValue="live"
            style={{ width: 120 }}
            onChange={(value) => {
              debugLog("Select onChange called with value:", value);
              setTableState(value);
              setCurrentPage(1); // Reset page number to 1
              updateStateRef({ tableState: value, currentPage: 1 });
            }}
          >
            <Option value="live">Live</Option>
            <Option value="archive">Archive</Option>
            <Option value="both">Both</Option>
          </Select>
          <button className="button" onClick={() => setShowModal(true)}>
            Add Record
          </button>

          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: totalRecords,
              position: ["bottomCenter"],
            }}
            onChange={handleTableChange}
            rowKey="test_id"
          />
          <button className="button" onClick={exportCSVFile}>
            Export to CSV
          </button>
        </>
      )}

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ display: "inline-block", margin: "auto" }}
        bodyClassName="center-toast"
      />

      {showModal && <AddRecordModal onClose={handleRecordAdded} />}
    </div>
  );
};

export default Records;
