// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { SERVER_URL } from "../utils/constants";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const { operatorName, rolename, isAdmin, userId, token, privateCode } =
        JSON.parse(storedUser);
      setUser({
        username: operatorName,
        role: rolename,
        isAdmin: isAdmin,
        userId: userId,
        token: token,
        privateCode: privateCode,
      });
    } else {
      setUser(null);
    }
    setLoading(false);
  }, []);

  const login = async (operatorName, password, privateCode) => {
    const response = await fetch(`${SERVER_URL}/HC/api/login.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        operatorName,
        password,
        privateCode,
        applicationType: "webapp",
      }),
    });
    const data = await response.json();
    if (data.statusCode === 200) {
      setUser({
        username: data.operatorName,
        role: data.rolename,
        isAdmin: data.isAdmin,
        userId: data.userId,
        token: data.token,
        privateCode: data.privateCode,
      });
      localStorage.setItem("user", JSON.stringify(data));
    } else {
      throw new Error(data.status);
    }
  };

  const apiLogout = async () => {
    await fetch(`${SERVER_URL}/HC/api/logout.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: user.token }),
    });
  };

  const logout = async () => {
    try {
      await apiLogout(); // Ensure the server clears any session related to the token
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      setUser(null);
      localStorage.removeItem("user");
      navigate("/login", { replace: true }); // Redirect to login after clearing local state
    }
  };

  const value = {
    user,
    login,
    logout,
    loading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
