import { useState, useEffect, useCallback } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const useUnsavedChangesAlert = (hasUnsavedChanges, setHasUnsavedChanges) => {
  const [triggerAlert, setTriggerAlert] = useState(false);

  const showAlert = useCallback(
    (callback) => {
      if (hasUnsavedChanges) {
        confirmAlert({
          title: "Unsaved Changes",
          message:
            "You have unsaved changes. Are you sure you want to change the product? All changes will be lost.",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                setHasUnsavedChanges(false);
                callback(true);
              },
            },
            {
              label: "No",
              onClick: () => {
                callback(false);
              },
            },
          ],
        });
      } else {
        callback(true);
      }
    },
    [hasUnsavedChanges, setHasUnsavedChanges]
  ); // hasUnsavedChanges is a dependency of showAlert

  useEffect(() => {
    if (triggerAlert) {
      showAlert(() => {
        setTriggerAlert(false);
      });
    }
  }, [triggerAlert, showAlert]); // Now showAlert is included in the dependency array

  return { showAlert, setTriggerAlert };
};

export default useUnsavedChangesAlert;
