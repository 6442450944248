import React from "react";
import "../assets/styles/StatusIndicator.css";

const StatusIndicator = ({ status }) => {
  return (
    <span className={`status-indicator ${status.toLowerCase()}`}>{status}</span>
  );
};

export default StatusIndicator;
