import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { SERVER_URL } from "../utils/constants";

const ShopFloorReport = () => {
  const [reportData, setReportData] = useState({});
  const [scrappedCount, setScrappedCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [productStageCounts, setProductStageCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user, logout } = useAuth();
  const [expandedStage] = useState({
    product: null,
    stage: null,
    type: null,
  });
  const [expandedRow, setExpandedRow] = useState({
    product: null,
    stage: null,
    type: null,
  });
  const [showArchived, setShowArchived] = useState(false);

  useEffect(() => {
    const fetchUrl = `${SERVER_URL}/HC/api/getShopFloorReport.php${
      showArchived ? "?includeArchived=1" : ""
    }`;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(fetchUrl, {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
        });
        setReportData(response.data);
        processReportData(response.data, showArchived); // Assuming processReportData is defined elsewhere
      } catch (error) {
        if (error.response && error.response.status === 401) {
          logout();
        } else {
          console.error("Error fetching shop floor report:", error);
          setError(error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      // Ensure user is not null before making the call
      fetchData();
    }
  }, [user, showArchived, logout]);

  const renderSerialDetails = (serials) => {
    // Ensure 'serials' is an array before mapping
    if (!Array.isArray(serials)) {
      return <div>No serial data available.</div>;
    }
    return Array.isArray(serials) ? (
      serials.map(([serial, info]) => (
        <div key={serial} className="serial-detail">
          <p>Serial: {serial}</p>
          <p>Completed Stages: {info.completed_stages.join(", ")}</p>
          <p>Current Stage: {info.current_stage}</p>
          <p>Is Scrapped: {info.is_scrapped ? "Yes" : "No"}</p>
        </div>
      ))
    ) : (
      <div>No serial data available.</div>
    );
  };

  const toggleRow = (productName, stage, type, count) => {
    if (count === 0) return; // Prevent expanding rows with zero count
    const isCurrentlyExpanded =
      expandedRow.product === productName &&
      expandedRow.stage === stage &&
      expandedRow.type === type;
    setExpandedRow(
      isCurrentlyExpanded
        ? { product: null, stage: null, type: null }
        : { product: productName, stage: stage, type: type }
    );
  };

  const renderRowDetails = (productName, stage, type) => {
    const serials = reportData[productName]?.serials;
    if (!serials) return <div>No data available for this stage.</div>;

    switch (type) {
      case "normal":
        return Object.entries(serials)
          .filter(([serial, info]) =>
            stage === "In Progress"
              ? info.current_stage.includes("in progress")
              : stage === "Completed Successfully"
              ? info.current_stage.includes("complete - success")
              : info.current_stage === stage
          )
          .map(([serial, info]) => (
            <div key={serial}>
              Serial: {serial}, Stage: {info.current_stage}
            </div>
          ));
      case "scrapped":
        return Object.entries(serials)
          .filter(([serial, info]) => info.is_scrapped)
          .map(([serial, info]) => (
            <div key={serial}>
              Serial: {serial}, Stage: {info.current_stage}
            </div>
          ));
      case "missing":
        return Object.entries(serials)
          .filter(([serial, info]) =>
            info.current_stage.includes("missing stages")
          )
          .map(([serial, info]) => (
            <div key={serial}>
              Serial: {serial}, Missing Stages: {info.current_stage}
            </div>
          ));
      default:
        return null;
    }
  };

  const renderExpandedDetails = (productName, stage, type) => {
    const serials = reportData[productName]?.serials;
    if (!serials) {
      return <div>No data available for this stage.</div>;
    }

    switch (type) {
      case "normal":
        return Object.entries(serials)
          .filter(([serial, info]) => info.current_stage === stage)
          .map(([serial, info]) => renderSerialDetails([[serial, info]]));

      case "scrapped":
        return Object.entries(serials)
          .filter(([serial, info]) => info.is_scrapped)
          .map(([serial, info]) => renderSerialDetails([[serial, info]]));

      case "missing":
        return Object.entries(serials)
          .filter(([serial, info]) =>
            info.current_stage.includes("missing stages")
          )
          .map(([serial, info]) => (
            <div key={serial} className="serial-detail">
              <p>Serial: {serial}</p>
              <p>
                Missing Stages: {renderMissingStages(reportData[productName])}
              </p>
            </div>
          ));

      default:
        return null;
    }
  };

  const renderMissingStages = (info) => {
    if (!info || !Array.isArray(info.full_route) || !info.serials) {
      return "No missing stages data available.";
    }

    // Assuming 'info.serials' is an object with serial numbers as keys
    const allSerials = Object.values(info.serials);

    const missingStages = info.full_route.filter(
      (stage) =>
        !allSerials.some((serialInfo) =>
          serialInfo.completed_stages.includes(stage)
        )
    );

    return missingStages.join(", ");
  };

  const processReportData = (data) => {
    const productWiseStageCounts = {};
    let overallScrappedCount = 0;
    let overallSuccessCount = 0;
    let overallInProgressCount = 0;

    Object.entries(data).forEach(([productName, productInfo]) => {
      const stageCounts = {};

      // Initialize all stages with a count of zero
      productInfo.full_route.forEach((stage) => {
        stageCounts[stage] = 0;
      });

      let scrappedCount = 0;
      let successCount = 0;
      let inProgressCount = 0;
      let missingStagesCount = 0;

      Object.entries(productInfo.serials || {}).forEach(
        ([serialNumber, serialInfo]) => {
          if (serialInfo.is_scrapped) {
            scrappedCount += 1;
            overallScrappedCount += 1;
          } else if (serialInfo.current_stage === "complete - success") {
            successCount += 1;
            overallSuccessCount += 1;
          } else if (
            serialInfo.current_stage === "complete - missing stages" ||
            serialInfo.current_stage === "in progress - missing stages"
          ) {
            missingStagesCount += 1;
          } else {
            const stage = serialInfo.current_stage;
            if (stageCounts.hasOwnProperty(stage)) {
              stageCounts[stage] += 1;
              inProgressCount += 1;
              overallInProgressCount += 1;
            }
          }
        }
      );

      productWiseStageCounts[productName] = {
        stages: stageCounts,
        scrapped: scrappedCount,
        success: successCount,
        inProgress: inProgressCount,
        missingStages: missingStagesCount,
      };
    });

    setProductStageCounts(productWiseStageCounts);
    setScrappedCount(overallScrappedCount);
    setSuccessCount(overallSuccessCount);
    setInProgressCount(overallInProgressCount);
  };

  if (isLoading) return <div className="loading-indicator">Loading...</div>;
  if (error) return <div className="error-message">Error: {error.message}</div>;

  return (
    <div className="shop-floor-report">
      <h1>Shop Floor Report</h1>

      <div className="filter-options">
        <label>
          <span class="checkboxtext">Show Archived</span>
          <input
            type="checkbox"
            checked={showArchived}
            onChange={(e) => setShowArchived(e.target.checked)}
          />
        </label>
      </div>

      {Object.entries(productStageCounts).map(([productName, counts]) => (
        <div key={productName} className="product-section">
          <h2>{productName}</h2>
          <table className="stage-counts">
            <thead>
              <tr>
                <th>Stage</th>
                <th>Serial Count</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(counts.stages).map((stage) => (
                <>
                  <tr
                    key={stage}
                    onClick={() =>
                      toggleRow(
                        productName,
                        stage,
                        "normal",
                        counts.stages[stage]
                      )
                    }
                  >
                    <td>{stage}</td>
                    <td>{counts.stages[stage]}</td>
                  </tr>
                  {expandedRow.product === productName &&
                    expandedRow.stage === stage && (
                      <tr>
                        <td colSpan="2">
                          {renderRowDetails(productName, stage, "normal")}
                        </td>
                      </tr>
                    )}
                </>
              ))}
              {showArchived && (
                <>
                  <tr
                    onClick={() =>
                      toggleRow(
                        productName,
                        "Completed Successfully",
                        "normal",
                        counts.success
                      )
                    }
                  >
                    <td>Completed Successfully</td>
                    <td>{counts.success}</td>
                  </tr>
                  {expandedRow.product === productName &&
                    expandedRow.stage === "Completed Successfully" && (
                      <tr>
                        <td colSpan="2">
                          {renderRowDetails(
                            productName,
                            "Completed Successfully",
                            "normal"
                          )}
                        </td>
                      </tr>
                    )}
                  <tr
                    onClick={() =>
                      toggleRow(
                        productName,
                        "Scrapped",
                        "scrapped",
                        counts.scrapped
                      )
                    }
                  >
                    <td>Scrapped</td>
                    <td>{counts.scrapped}</td>
                  </tr>
                  {expandedRow.product === productName &&
                    expandedRow.stage === "Scrapped" && (
                      <tr>
                        <td colSpan="2">
                          {renderRowDetails(
                            productName,
                            "Scrapped",
                            "scrapped"
                          )}
                        </td>
                      </tr>
                    )}
                </>
              )}
              <tr
                onClick={() =>
                  toggleRow(
                    productName,
                    "Missing Stages",
                    "missing",
                    counts.missingStages
                  )
                }
              >
                <td>Missing Stages</td>
                <td>{counts.missingStages}</td>
              </tr>
              {expandedRow.product === productName &&
                expandedRow.stage === "Missing Stages" && (
                  <tr>
                    <td colSpan="2">
                      {renderRowDetails(
                        productName,
                        "Missing Stages",
                        "missing"
                      )}
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          {expandedStage.product === productName && (
            <div className="expanded-stage-details">
              {renderExpandedDetails(
                expandedStage.product,
                expandedStage.stage,
                expandedStage.type
              )}
            </div>
          )}
        </div>
      ))}
      <div className="totals">
        {showArchived && (
          <>
            <h2>Total Completed Successfully Across All Products</h2>
            <p>{successCount}</p>
          </>
        )}
        <h2>Total In Progress Across All Products</h2>
        <p>{inProgressCount}</p>
        {showArchived && (
          <>
            <h2>Total Scrapped Across All Products!</h2>
            <p>{scrappedCount}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ShopFloorReport;
