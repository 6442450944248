import React, { useEffect, useState, useCallback } from "react";
import AddUserModal from "../models/AddUserModel";
import { useAuth } from "../contexts/AuthContext";
import { SERVER_URL } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { AiOutlineUserAdd, AiOutlineEdit } from "react-icons/ai";
import { TiCancel } from "react-icons/ti";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAlert from "../hooks/useAlert";

function UserManager({ hasUnsavedChanges, setHasUnsavedChanges }) {
  const [users, setUsers] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [roles, setRoles] = useState([]);
  const { user, logout } = useAuth();
  const [updatedUsers, setUpdatedUsers] = useState([]);
  const navigate = useNavigate();

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  const openAddUserModal = () => {
    setIsAddUserModalOpen(true);
  };

  const closeAddUserModal = () => {
    setIsAddUserModalOpen(false);
  };

  const unarchiveUser = async (userId) => {
    const response = await fetch(
      `${SERVER_URL}/HC/api/unArchiveUser.php?id=${userId}`,
      {
        method: "PUT",
        headers: {
          Authorization: user.token,
          PrivateCode: user.privateCode,
        },
      }
    );

    if (!response.ok) {
      toast.error("Failed to unarchive user.");
      throw new Error(`Failed to unarchive user: ${response.statusText}`);
    }

    const { message } = await response.json();
    toast.success(message);

    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, is_active: "1" } : user
      )
    );
  };

  const handleUnarchiveClick = (userId) => {
    showAlert(
      true,
      "Unarchive User",
      "Are you sure you want to unarchive this user?",
      (confirmed) => {
        if (confirmed) {
          unarchiveUser(userId);
        }
      },
      "confirm"
    );
  };

  const showAlert = useAlert();

  const handleDeleteClick = async (userId) => {
    // Call the archiveUser.php endpoint
    const response = await fetch(
      `${SERVER_URL}/HC/api/deleteOrArchiveUser.php?id=${userId}`,
      {
        method: "GET",
        headers: {
          Authorization: user.token,
          PrivateCode: user.privateCode,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to check user records: ${response.statusText}`);
    }

    const { hasRecords } = await response.json();

    // Show a different message based on whether the user has any records
    const title = hasRecords ? "Archive User" : "Delete User";
    const message = hasRecords
      ? "This user has records attached, Are you sure you want to archive this user?"
      : "Are you sure you want to delete this user? This action cannot be undone.";

    showAlert(
      true, // Condition to show alert
      title, // Alert title
      message, // Alert message
      async (proceed) => {
        if (!proceed) {
          return;
        } else {
          const response = await fetch(
            `${SERVER_URL}/HC/api/deleteOrArchiveUser.php?id=${userId}`,
            {
              method: "DELETE",
              headers: {
                Authorization: user.token,
                PrivateCode: user.privateCode,
              },
            }
          );

          if (!response.ok) {
            toast.error("Failed to delete user.");
            throw new Error(`Failed to delete user: ${response.statusText}`);
          }

          const { message } = await response.json();
          toast.success(message);

          // Remove the user from the users array
          if (hasRecords) {
            setUsers((prevUsers) =>
              prevUsers.map((user) =>
                user.id === userId ? { ...user, is_active: "0" } : user
              )
            );
          } else {
            setUsers((prevUsers) =>
              prevUsers.filter((user) => user.id !== userId)
            );
          }
        }
      },
      "confirm" // Alert type
    );
  };

  const fetchUsers = useCallback(async () => {
    const response = await fetch(`${SERVER_URL}/HC/api/getUserList.php`, {
      headers: {
        Authorization: user.token,
        PrivateCode: user.privateCode,
      },
    });
    if (response.status === 401) {
      navigate("/login");
      return;
    }

    const data = await response.json();
    setUsers(data);

    const rolesResponse = await fetch(`${SERVER_URL}/HC/api/getRoles.php`, {
      headers: {
        Authorization: user.token,
        PrivateCode: user.privateCode,
      },
    });
    const rolesData = await rolesResponse.json();
    setRoles(rolesData);

    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        const role = rolesData.find((role) => role.id === user.roleId);
        return {
          ...user,
          rolename: role ? role.rolename : "",
        };
      })
    );
  }, [user.token, user.privateCode, navigate]);

  useEffect(() => {
    fetchUsers();

    return () => {
      setHasUnsavedChanges(false);
    };
  }, [fetchUsers, setHasUnsavedChanges]);

  const handlePasswordClick = (userId) => {
    const updatedUsers = users.map((user) => {
      if (user.id === userId) {
        return { ...user, showPasswordInput: true };
      } else {
        return user;
      }
    });
    setUsers(updatedUsers);
  };

  const handlePasswordChange = (event, id) => {
    const updatedUser = users.find((user) => user.id === id);
    if (updatedUser) {
      updatedUser.password = event.target.value;
      setUpdatedUsers((prevUsers) => [...prevUsers, updatedUser]);
    }
    setHasUnsavedChanges(true);
  };

  const handleCancelPasswordClick = (userId) => {
    const updatedUsers = users.map((user) => {
      if (user.id === userId) {
        return { ...user, showPasswordInput: false };
      } else {
        return user;
      }
    });
    setUsers(updatedUsers);
  };

  const handleRoleChange = (event, id) => {
    const updatedUser = users.find((user) => user.id === id);
    if (updatedUser) {
      updatedUser.roleId = parseInt(event.target.value);
      setUpdatedUsers((prevUsers) => [...prevUsers, updatedUser]);
    }
    setHasUnsavedChanges(true);
  };

  const onUserAdded = (data) => {
    if (data.error) {
      toast.error(data.error);
    } else {
      toast.success(data.message);
      fetchUsers();
      closeAddUserModal(); // Close modal after adding user
    }
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/HC/api/updateUsers.php`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: user.token,
          PrivateCode: user.privateCode,
        },
        body: JSON.stringify(updatedUsers),
      });

      if (response.status !== 200) {
        throw new Error("Failed to update data.");
      }

      const responseData = await response.json();

      if (responseData.message) {
        toast.success(responseData.message);

        // Merge the updated users back into the users array
        const newUsers = [...users];
        updatedUsers.forEach((updatedUser) => {
          const index = newUsers.findIndex(
            (user) => user.id === updatedUser.id
          );
          if (index !== -1) {
            newUsers[index] = updatedUser;
          }
        });
        setUsers(newUsers);

        // Clear the updated users array
        setUpdatedUsers([]);

        // Fetch the users again to ensure local state matches server-side state
        fetchUsers();
      } else if (responseData.error) {
        console.error(responseData.error);
      }

      setHasUnsavedChanges(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
      }
      console.error(error);
    }
  };

  return (
    <div>
      <h1>User Manager</h1>
      <p>This is the User manager page.</p>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName="center-toast"
      />
      {/* Add User button */}
      <div className="button-container">
        <button className="button" onClick={openAddUserModal}>
          <AiOutlineUserAdd className="icon" />
          Add User
        </button>
      </div>

      {/* Show Archived Users Toggle */}
      <div className="checkbox-container">
        <label>
          <input
            type="checkbox"
            checked={showArchived}
            onChange={(event) => setShowArchived(event.target.checked)}
          />
          <span class="checkboxtext">Show Archived Users</span>
        </label>
      </div>

      <br />

      {/* Add User Modal */}
      {isAddUserModalOpen && (
        <AddUserModal
          roles={roles}
          closeAddUserModal={closeAddUserModal}
          setUsers={setUsers}
          onUserAdded={onUserAdded}
        />
      )}
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Operator Name</th>
            <th>Password</th>
            <th>Role</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {users.map(
            (user) =>
              // Show archived users only if showArchived is true
              (user.is_active === "0" && !showArchived) || (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>
                    {user.is_active === "0" ? (
                      <span style={{ color: "grey" }}>
                        {user.operatorName} (Archived)
                      </span>
                    ) : (
                      <>{user.operatorName}</>
                    )}
                  </td>
                  <td>
                    {user.showPasswordInput ? (
                      <>
                        <input
                          type="text"
                          value={user.password}
                          onChange={(event) =>
                            handlePasswordChange(event, user.id)
                          }
                        />
                        <button
                          class="button"
                          onClick={() => handleCancelPasswordClick(user.id)}
                        >
                          <TiCancel className="icon" />
                        </button>
                      </>
                    ) : (
                      <button
                        class="button"
                        onClick={() => handlePasswordClick(user.id)}
                      >
                        <AiOutlineEdit />
                        Change Password
                      </button>
                    )}
                  </td>
                  <td>
                    <select
                      value={user.roleId}
                      onChange={(event) => handleRoleChange(event, user.id)}
                    >
                      {roles.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.rolename}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    {user.is_active === "0" ? (
                      <button
                        className="button"
                        onClick={() => handleUnarchiveClick(user.id)}
                      >
                        Unarchive
                      </button>
                    ) : (
                      <button
                        className="button"
                        onClick={() => handleDeleteClick(user.id)}
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
      <button class="button" onClick={handleSave}>
        Save
      </button>
    </div>
  );
}

export default UserManager;
