import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import "../dashboard.css";
import { SERVER_URL } from "../utils/constants";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [operatorName, setOperatorName] = useState("");
  const [serial, setSerial] = useState("");
  const [workorder, setWorkOrder] = useState("");
  const [product, setProduct] = useState("");
  const [process, setProcess] = useState("");
  const [recordsStatus, setRecordsStatus] = useState("both");
  const { user, logout } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Add the recordsStatus to the request
        const result = await axios.get(`${SERVER_URL}/HC/api/getRecords.php`, {
          headers: {
            Authorization: user.token,
            role: user.role,
            userId: user.userId,
            tableState: recordsStatus.toLowerCase(),
            PrivateCode: user.privateCode,
          },
        });

        setData(result.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          logout();
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [
    user.token,
    user.role,
    user.userId,
    user.privateCode,
    recordsStatus,
    logout,
  ]);

  const filterData = (
    data,
    operatorName,
    serial,
    workorder,
    product,
    process
  ) => {
    let filteredData = data;

    if (operatorName) {
      filteredData = filteredData.filter(
        (item) => item.operatorName === operatorName
      );
    }

    if (serial) {
      filteredData = filteredData.filter((item) => item.serial === serial);
    }

    if (workorder) {
      filteredData = filteredData.filter(
        (item) => item.workorder === workorder
      );
    }

    if (product) {
      filteredData = filteredData.filter((item) => item.product === product);
    }

    if (process) {
      filteredData = filteredData.filter((item) => item.process === process);
    }

    return filteredData;
  };

  const processDataForBarChart = (data) => {
    const processWiseData = data.reduce((acc, item) => {
      if (!acc[item.process]) {
        acc[item.process] = { PASS: 0, FAIL: 0, RESOLVED: 0 };
      }
      if (item.result === "PASS") {
        acc[item.process].PASS += 1;
      } else if (item.result === "FAIL") {
        acc[item.process].FAIL += 1;
      } else if (item.result === "RESOLVED") {
        acc[item.process].RESOLVED += 1;
      }
      return acc;
    }, {});

    return Object.entries(processWiseData).map(
      ([process, { PASS, FAIL, RESOLVED }]) => ({
        name: process,
        PASS,
        FAIL,
        RESOLVED,
      })
    );
  };

  const processDataForPieChart = (data) => {
    // Process data for pie chart
    const statusCount = data.reduce((acc, item) => {
      if (!acc[item.result]) {
        acc[item.result] = 0;
      }

      acc[item.result] += 1;

      return acc;
    }, {});

    return Object.entries(statusCount).map(([key, value]) => ({
      name: key,
      value: value,
    }));
  };

  const processDataForLineChart = (data) => {
    // Process data for line chart
    const dailyData = data.reduce((acc, item) => {
      const date = item.date_entered.split(" ")[0];

      if (!acc[date]) {
        acc[date] = { name: date, PASS: 0, FAIL: 0, RESOLVED: 0 };
      }

      if (item.result === "PASS") {
        acc[date].PASS += 1;
      } else if (item.result === "FAIL") {
        acc[date].FAIL += 1;
      } else if (item.result === "RESOLVED") {
        acc[date].RESOLVED += 1;
      }

      return acc;
    }, {});

    return Object.values(dailyData);
  };

  const filteredData = filterData(
    data,
    operatorName,
    serial,
    workorder,
    product,
    process
  );

  const barChartData = processDataForBarChart(filteredData);
  const pieChartData = processDataForPieChart(filteredData);
  const lineChartData = processDataForLineChart(filteredData);

  const extractUniqueOperators = (data) => {
    const operatorsSet = new Set(data.map((item) => item.operatorName));
    return Array.from(operatorsSet);
  };

  const extractUniqueSerials = (data) => {
    const serialsSet = new Set(data.map((item) => item.serial));
    return Array.from(serialsSet);
  };

  const extractUniqueWorkOrders = (data) => {
    const workOrdersSet = new Set(data.map((item) => item.workorder));
    return Array.from(workOrdersSet);
  };

  const extractUniqueProducts = (data) => {
    const productsSet = new Set(data.map((item) => item.product));
    return Array.from(productsSet);
  };

  const extractUniqueProcesses = (data) => {
    const processesSet = new Set(data.map((item) => item.process));
    return Array.from(processesSet);
  };

  const uniqueOperators = extractUniqueOperators(filteredData);
  const uniqueSerials = extractUniqueSerials(filteredData);
  const uniqueWorkOrders = extractUniqueWorkOrders(filteredData);
  const uniqueProducts = extractUniqueProducts(filteredData);
  const uniqueProcesses = extractUniqueProcesses(filteredData);

  return (
    <div>
      <h2>Dashboard</h2>
      <FormControl className="form-control">
        <InputLabel>Status</InputLabel>
        <Select
          value={recordsStatus}
          onChange={(e) => setRecordsStatus(e.target.value)}
        >
          {["Live", "Archive", "Both"].map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className="form-control">
        <InputLabel>Product</InputLabel>
        <Select value={product} onChange={(e) => setProduct(e.target.value)}>
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {uniqueProducts.map((product) => (
            <MenuItem key={product} value={product}>
              {product}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className="form-control">
        <InputLabel>Operator</InputLabel>
        <Select
          value={operatorName}
          onChange={(e) => setOperatorName(e.target.value)}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {uniqueOperators.map((operatorName) => (
            <MenuItem key={operatorName} value={operatorName}>
              {operatorName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className="form-control">
        <InputLabel>Serial</InputLabel>
        <Select value={serial} onChange={(e) => setSerial(e.target.value)}>
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {uniqueSerials.map((serial) => (
            <MenuItem key={serial} value={serial}>
              {serial}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className="form-control">
        <InputLabel>Work Order</InputLabel>
        <Select
          value={workorder}
          onChange={(e) => setWorkOrder(e.target.value)}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {uniqueWorkOrders.map((workorder) => (
            <MenuItem key={workorder} value={workorder}>
              {workorder}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className="form-control">
        <InputLabel>Process</InputLabel>
        <Select value={process} onChange={(e) => setProcess(e.target.value)}>
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {uniqueProcesses.map((process) => (
            <MenuItem key={process} value={process}>
              {process}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Bar Chart */}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={barChartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="PASS" fill="#00b930" />
          <Bar dataKey="FAIL" fill="#b90000" />
          <Bar dataKey="RESOLVED" fill="#0019b9" />
        </BarChart>
      </ResponsiveContainer>

      {/* Pie Chart */}
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={pieChartData}
            cx={200}
            cy={200}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {pieChartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>

      {/* Line Chart */}
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={lineChartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="PASS"
            stroke="#00b930"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey="FAIL"
            stroke="#b90000"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey="RESOLVED"
            stroke="#0019b9"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Dashboard;
