// Audit.js
import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Table, Input, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useAuth } from "../contexts/AuthContext";
import "../records.css";
import { SERVER_URL } from "../utils/constants";
import AuditDetailsModal from "../models/AuditDetailsModal";

const Audit = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const { user, logout } = useAuth();

  const [auditDetailsVisible, setAuditDetailsVisible] = useState(false);
  const [currentAuditId, setCurrentAuditId] = useState(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setFilteredData(
      data.filter((item) =>
        item[dataIndex].toLowerCase().includes(selectedKeys[0].toLowerCase())
      )
    );
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setFilteredData(data);
  };

  const fetchData = useCallback(async () => {
    try {
      const result = await axios.get(`${SERVER_URL}/HC/api/auditviewer.php`, {
        headers: {
          Authorization: user.token,
          role: user.role,
          userId: user.userId,
          PrivateCode: user.privateCode,
        },
      });
      setData(result.data); // Assuming result.data contains the response data directly
      setFilteredData(result.data); // Assuming you want to set filtered data as well
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout(); // Use logout from AuthContext which should handle redirection as well
      } else {
        console.error("Error fetching data:", error);
        // Optionally handle other statuses or general network errors
      }
    }
  }, [user.token, user.role, user.userId, user.privateCode, logout]); // Include logout in dependencies

  useEffect(() => {
    fetchData();
  }, [fetchData]); // Dependency array includes fetchData

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const columns = [
    {
      title: "Operator Name",
      dataIndex: "user_name",
      key: "user_name",
      sorter: (a, b) => a.user_name - b.user_name,
      ...getColumnSearchProps("user_name"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      ...getColumnSearchProps("action"),
    },
    {
      title: "IP Address",
      dataIndex: "ip_address",
      key: "ip_address",
      ...getColumnSearchProps("ip_address"),
    },
    {
      title: "Endpoint Type",
      dataIndex: "endpoint_type",
      key: "endpoint_type",
      ...getColumnSearchProps("endpoint_type"),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      ...getColumnSearchProps("details"),
    },
    {
      title: "Logged At",
      dataIndex: "timestamp",
      key: "timestamp",
      ...getColumnSearchProps("timestamp"),
    },
  ];

  const showAuditDetails = (record) => {
    setCurrentAuditId(record.id);
    setAuditDetailsVisible(true);
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <div className="records">
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          position: ["bottomCenter"],
        }}
        onChange={handleTableChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => showAuditDetails(record), // click row to show audit details
          };
        }}
        rowKey="id"
      />
      <AuditDetailsModal
        isOpen={auditDetailsVisible}
        onRequestClose={() => setAuditDetailsVisible(false)}
        onSubmit={(details) => {
          // Here you would typically send the details to the server
          console.log(details);
          setAuditDetailsVisible(false);
        }}
        auditId={currentAuditId}
        user={user}
      />
    </div>
  );
};

export default Audit;
