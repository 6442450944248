import { useCallback } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const useAlert = () => {
  const showAlert = useCallback(
    (hasCondition, title, message, callback, alertType = "confirm") => {
      if (!hasCondition) {
        callback(true);
        return;
      }

      const options = {
        title: title,
        message: message,
        buttons: [],
      };

      if (alertType === "confirm") {
        options.buttons = [
          {
            label: "Yes",
            onClick: () => callback(true),
          },
          {
            label: "No",
            onClick: () => callback(false),
          },
        ];
      } else if (alertType === "alert") {
        options.buttons = [
          {
            label: "OK",
            onClick: () => callback(true),
          },
        ];
      }

      confirmAlert(options);
    },
    []
  );

  return showAlert;
};

export default useAlert;
