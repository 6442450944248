import React, { useState } from "react";
import Modal from "react-modal";
import "../assets/styles/FormModel.css"; // Import the CSS

Modal.setAppElement("#root");

const CloneProductModal = ({
  isOpen,
  onRequestClose,
  onSubmit,
  initialData,
}) => {
  const [productName, setProductName] = useState(
    initialData?.productName || ""
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!productName) {
      alert("Please enter a product name.");
      return;
    }

    onSubmit({ productName });
    setProductName("");
  };

  return (
    <div className={isOpen ? "form-modal" : ""}>
      {isOpen && (
        <div className="form-modal-content">
          <h2>Clone Product</h2>
          <button className="close-button" onClick={onRequestClose}>
            &times;
          </button>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="product-name">Product Name:</label>
              <input
                type="text"
                placeholder="New Product Name"
                name="productName"
                id="product-name"
                className="form-control"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>
            <button type="submit" name="cloneProduct" className="add-button">
              Clone Product
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default CloneProductModal;
