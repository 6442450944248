import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaRobot } from "react-icons/fa";
import Joyride from "react-joyride";

const FloatingRobot = styled.button`
  position: fixed;
  top: 15px;
  right: 15px;
  background-color: ${(props) => (props.enabled ? "#28a745" : "#dc3545")};
  color: white;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const JoyrideToggle = ({ steps }) => {
  const [isJoyRideEnabled, setIsJoyRideEnabled] = useState(
    localStorage.getItem("isJoyRideEnabled") === "true"
  );
  const [runTour, setRunTour] = useState(isJoyRideEnabled);

  useEffect(() => {
    setRunTour(isJoyRideEnabled);
  }, [isJoyRideEnabled]);

  const toggleJoyride = () => {
    const newState = !isJoyRideEnabled;
    setIsJoyRideEnabled(newState);
    localStorage.setItem("isJoyRideEnabled", newState);
  };

  return (
    <>
      {/* Floating Robot Button to Toggle Joyride */}
      <FloatingRobot
        enabled={isJoyRideEnabled}
        onClick={toggleJoyride}
        title="Toggle Guide"
      >
        <FaRobot />
      </FloatingRobot>

      {/* Display Joyride if enabled */}
      {isJoyRideEnabled && (
        <Joyride
          steps={steps}
          run={runTour}
          continuous
          showSkipButton
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
    </>
  );
};

export default JoyrideToggle;
