import React, { useState, useEffect, useRef, useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import axios from "axios";
import Section from "../components/Section";
import Item from "../components/Item";
import "../App.css";
import PublishSection from "../components/PublishSection";
import "react-confirm-alert/src/react-confirm-alert.css";
import useAlert from "../hooks/useAlert";
import AddProductModal from "../models/AddProductModal";
import AddProcessModal from "../models/AddProcessModal";
import EditProductModal from "../models/EditProductModal";
import { useAuth } from "../contexts/AuthContext";
import { SERVER_URL } from "../utils/constants";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { AiOutlinePlus } from "react-icons/ai";
import { IoDuplicateOutline } from "react-icons/io5";
import JoyrideToggle from "../components/JoyRideToggle"; // Import component
import CloneProductModal from "../models/CloneProduct";

function ProductRoutes({ hasUnsavedChanges, setHasUnsavedChanges }) {
  const [items, setItems] = useState([]);
  const [sections, setSections] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const lastSelectedProductRef = useRef(null);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showCloneProductModal, setShowCloneProductModal] = useState(false);
  const [isAddProcessModalOpen, setIsAddProcessModalOpen] = useState(false);
  const { user, logout } = useAuth();
  const [filterText, setFilterText] = useState("");
  const [showEditProductModal, setShowEditProductModal] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [showArchived, setShowArchived] = useState(false);

  const steps = [
    {
      target: ".add-product-button",
      content: "Click here to add a new product.",
    },
    {
      target: ".product-select",
      content:
        "Select a product from this dropdown to start editing its route.",
    },
    {
      target: ".add-process-button",
      content: "Click here to add a new process to the global list.",
    },
    {
      target: ".process-list",
      content: "Drag any of these processes into a product section.",
      disableBeacon: true, // ensures it opens right away
    },
    {
      target: ".section-items",
      content: "Drop your process here. Then drag to reorder as needed.",
    },
    {
      target: ".publish-button",
      content: "Finally, click Publish to save your changes.",
      placement: "top",
    },
  ];

  const handleCloneProductSubmit = async (newProductName) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/HC/api/cloneProduct.php`,
        { originalProductId: selectedProduct.product_id, newProductName },
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        toast.success("Product cloned successfully.");
        fetchProducts(); // Refresh the products list
      } else {
        throw new Error("Error cloning product.");
      }
    } catch (error) {
      let errorMessage = "Error cloning product.";
      if (error.response) {
        if (error.response.status === 401) {
          logout();
        } else {
          errorMessage = error.response.data.message || errorMessage;
        }
      }

      toast.error(errorMessage);
    }
  };

  const handleArchivedChange = () => {
    const newShowArchived = !showArchived;
    setShowArchived(newShowArchived);

    // If we're hiding archived products and the currently selected product is archived, reset the selection
    if (!newShowArchived && selectedProduct?.isArchived === 1) {
      setSelectedProduct(null);
      setSelectedOption(null);
    }

    localStorage.setItem("showArchived", newShowArchived);
    fetchProducts(newShowArchived);
  };

  const handleEditProductClick = (product) => {
    setEditingProduct(product);
    setShowEditProductModal(true);
  };

  const handleEditProductSubmit = async (updatedProduct) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/HC/api/updateProduct.php`,
        JSON.stringify({
          productId: updatedProduct.product_id,
          newProductName: updatedProduct.productName,
        }),
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);
        const updatedProducts = products.map((product) => {
          if (product.product_id === updatedProduct.product_id) {
            return { ...product, product: updatedProduct.productName };
          }
          return product;
        });
        setProducts(updatedProducts);
        await fetchProducts();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
      } else {
        toast.error(
          error.response
            ? error.response.data.message
            : "An error occurred while updating the product."
        );
      }
    }
    setShowEditProductModal(false);
  };

  const showAlert = useAlert();

  const removeProcessesFromSections = (deletedItemIds) => {
    const updatedSections = sections.map((section) => {
      const filteredItems = section.items.filter(
        (item) => !deletedItemIds.includes(item.id)
      );
      return { ...section, items: filteredItems };
    });
    setSections(updatedSections);
    setHasUnsavedChanges(false);
  };

  const handleDeleteProductClick = async (product) => {
    showAlert(
      true, // Condition to show alert
      "Delete or Archive Product", // Alert title
      `Are you sure you want to delete ${product.product}? If the product has records in the archive, you can choose to archive it instead.`, // Alert message
      async (proceed, archive) => {
        if (!proceed) {
          return;
        } else {
          const itemIdToRemove = items
            .filter((item) => item.productId === product.productId)
            .map((item) => item.id);
          await removeOrArchiveProduct(product.product_id, archive);
          removeProcessesFromSections(itemIdToRemove);
        }
      },
      "confirm" // Alert type
    );
  };

  const removeOrArchiveProduct = async (productId, archive = false) => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/HC/api/deleteProduct.php`,
        {
          data: { productId, archive },
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        const successMessage = archive
          ? "Product archived successfully"
          : "Product deleted successfully";
        toast.success(successMessage);
        if (archive && selectedProduct?.product_id === productId) {
          const updatedProduct = { ...selectedProduct, isArchived: true }; // Mark as archived
          setSelectedProduct(updatedProduct);
          setSelectedOption({
            value: updatedProduct.product_id,
            label: updatedProduct.product,
          });
          fetchProducts();
        } else if (!archive) {
          setSelectedProduct(null);
          setSelectedOption(null);
          await fetchProducts();
        }
      } else if (response.data.warning) {
        // Show confirmation dialog
        showAlert(
          true,
          "Archive Product",
          response.data.warning,
          (confirmArchive) => {
            if (confirmArchive) {
              // User chose to archive the product
              removeOrArchiveProduct(productId, true);
            }
          },
          "confirm"
        );
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      let errorMessage = "Error deleting or archiving product.";
      if (error.response && error.response.status === 401) {
        logout();
      } else {
        errorMessage = error.response
          ? error.response.data.message
          : errorMessage;
        toast.error(errorMessage);
      }
    }
  };

  const handleUnarchiveProduct = async (product) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/HC/api/unarchiveProduct.php`,
        {
          productId: product.product_id,
        },
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        toast.success("Product unarchived successfully");
        await fetchProducts();
        const updatedProduct = response.data.product || product; // Use the updated product data if available

        // Update the selectedProduct and selectedOption with the updated product data
        setSelectedProduct(updatedProduct);
        setSelectedOption({
          value: updatedProduct.product_id,
          label: updatedProduct.product,
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
      }
      toast.error("Error unarchiving product");
    }
  };

  const isItemInSection = (itemId) => {
    return sections.some((section) =>
      section.items.some((item) => item.id === itemId)
    );
  };

  // Function to handle filter text change
  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  // Filtered list of items not in a section
  const filteredItems = items.filter(
    (item) =>
      !isItemInSection(item.id) &&
      item.process.toLowerCase().includes(filterText.toLowerCase())
  );

  const getProcessNameFromId = useCallback(
    (processId) => {
      const process = items.find((item) => item.id === processId);
      return process ? process.process : "";
    },
    [items]
  );

  const fetchProducts = useCallback(async () => {
    // Determine the value of archived based on the showArchived state
    const isArchived = localStorage.getItem("showArchived") === "true";

    try {
      const response = await axios.get(
        `${SERVER_URL}/HC/api/getProductList.php`,
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
          params: {
            archived: isArchived,
          },
        }
      );
      if (response.status === 401) {
        logout();
      }
      if (response.status === 200) {
        setProducts(response.data);
        if (response.data.length > 0) {
          setSelectedProduct(response.data[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }, [user.token, user.privateCode, logout]);

  // Function to submit the new name
  const submitProcessNameChange = async (
    processId,
    newName,
    forceFlag = false
  ) => {
    try {
      const force = !!forceFlag;
      const response = await axios.post(
        `${SERVER_URL}/HC/api/updateProcess.php`,
        JSON.stringify({ processId, newProcessName: newName, force }),
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);
        fetchItems();
      } else if (response.data.warning) {
        showAlert(
          true,
          "Warning",
          response.data.warning,
          (proceed) => {
            if (proceed) {
              submitProcessNameChange(processId, newName, true); // Call with force = true only if user confirms
              fetchItems();
            }
          },
          "confirm",
          "Proceed Anyway",
          "Cancel"
        );
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle unauthorized access
        logout();
      } else {
        toast.error(error.response.data.message);
      }
    }
  };

  const handleFetchedSections = useCallback(
    async (productId) => {
      // Destructure properties from selectedProduct
      const { product, product_id } = selectedProduct || {};

      const productData = products.find((p) => p.product_id === productId);
      setSelectedProduct(productData);

      if (!productData) return;

      try {
        const response = await axios.get(
          `${SERVER_URL}/HC/api/route.php?product_id=${productId}`,
          {
            headers: {
              Authorization: user.token,
              PrivateCode: user.privateCode,
            },
          }
        );

        if (response.status === 401) {
          logout();
        }

        // Check if response.data.sections is defined
        if (response.data.sections) {
          const fetchedSections = response.data.sections;

          // Map items in the fetched sections to include process names
          const updatedSections = fetchedSections.map((section, index) => {
            const updatedItems = section.items.map((item, itemIndex) => {
              // Set verify_prev to false for the first item
              const verifyPrevValue =
                itemIndex === 0 ? false : item.verify_prev;
              return {
                ...item,
                process: getProcessNameFromId(item.id),
                verify_prev: verifyPrevValue,
              };
            });

            return {
              ...section,
              items: updatedItems,
            };
          });

          setSections(updatedSections);
        } else {
          const newSection = {
            id: product_id, // Use destructured product_id
            name: product, // Use destructured product
            items: [],
          };
          setSections((prevSections) => [newSection]);
        }
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
    },
    [
      products,
      user.token,
      user.privateCode,
      logout,
      getProcessNameFromId,
      selectedProduct, // Keep selectedProduct in the dependency array
    ]
  );

  const [selectedOption, setSelectedOption] = useState(null);

  const productOptions = products.map((product) => ({
    value: product.product_id,
    label: product.product,
  }));

  //When initializing, ensure the selectedOption is set correctly if there's a default or initial selection
  useEffect(() => {
    if (selectedProduct) {
      const initialSelection = products.find(
        (product) => product.product_id === selectedProduct.product_id
      )?.product;
      if (initialSelection) {
        setSelectedOption({
          value: selectedProduct.product_id,
          label: initialSelection,
        });
      }
    }
  }, [products, selectedProduct]);

  const handleProductSelectChange = (option) => {
    showAlert(
      hasUnsavedChanges, // Condition to show alert
      "Unsaved Changes", // Alert title
      "You have unsaved changes. Are you sure you want to change the product? All changes will be lost.", // Alert message
      async (proceed) => {
        if (proceed) {
          // User confirmed, update the selected product
          setSelectedOption(option); // Update react-select control
          const productId = option.value;
          const product = products.find((p) => p.product_id === productId);
          lastSelectedProductRef.current = product; // Update the last selected product ref
          setSelectedProduct(product); // Update the selected product
          await handleFetchedSections(productId); // Perform related tasks
          setHasUnsavedChanges(false); // Reset unsaved changes flag
        }
        // If not proceeding, do not change the selectedOption state to keep the current selection
      },
      "confirm"
    );
  };

  const fetchItems = useCallback(async () => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/HC/api/getProcessList.php`,
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
        }
      );
      const newItems = response.data.map((item) => ({
        id: item.process_id,
        process: item.process,
        collect_serial: item.collect_serial ?? false, // default to false
      }));
      setItems(newItems);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
      }
      console.error("Error fetching items:", error);
      setItems([]);
    }
  }, [user.token, user.privateCode, logout]);

  const handleAddProcessSubmit = async (newProcessData) => {
    try {
      // Replace with your actual API call to create a new process
      const response = await axios.post(
        `${SERVER_URL}/HC/api/getProcessList.php`,
        newProcessData,
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
        }
      );
      fetchItems(); // Refresh the processes list
      setIsAddProcessModalOpen(false); // Close the modal
      if (response.status === 200) {
        const successMessage =
          response.data.message || "New process created successfully";
        toast.success(successMessage);
      }
    } catch (error) {
      let errorMessage = "Error adding new process.";
      if (error.response) {
        if (error.response.status === 401) {
          logout();
        } else {
          errorMessage = error.response.data.message || errorMessage;
        }
      }

      setIsAddProcessModalOpen(false);
      toast.error(errorMessage);
    }
  };

  const submitData = async (forceFlag = false) => {
    try {
      const force = !!forceFlag;
      const dataToSend = sections.map((section) => ({
        ...section,
        product_id: selectedProduct.product_id,
        force,
      }));

      const response = await axios.post(
        `${SERVER_URL}/HC/api/route.php`,
        JSON.stringify(dataToSend),
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        setHasUnsavedChanges(false);
        showAlert(
          true,
          "Success",
          "Route updated successfully.",
          () => {}, // No additional action on OK
          "alert"
        );
      } else if (response.data.warning) {
        showAlert(
          true,
          "Warning",
          response.data.warning,
          (proceed) => {
            if (proceed) {
              submitData(true); // Call with force = true only if user confirms
            }
          },
          "confirm",
          "Proceed Anyway",
          "Cancel"
        );
      } else {
        showAlert(
          true,
          "Error",
          response.data.message ||
            "An error occurred while updating the route.",
          () => {}, // No additional action on OK
          "alert"
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
      } else {
        console.error("Error submitting data:", error);
        showAlert(
          true,
          "Error",
          error.message || "An error occurred while submitting the data.",
          () => {}, // No additional action on OK
          "alert"
        );
      }
    }
  };
  const handleAddProductSubmit = async (newProductData) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/HC/api/createproduct.php`,
        { product: newProductData.productName },
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
        }
      );

      const createdProduct = response.data.createdProduct;
      if (createdProduct) {
        const successMessage =
          response.data.message || "New product created successfully";
        // Update the products state with the new product and refetch the products list
        await setProducts((products) => [...products, createdProduct]);
        await fetchProducts();

        // Close the modal
        setShowAddProductModal(false);

        toast.success(successMessage);
        fetchItems();
      }
    } catch (error) {
      let errorMessage = "Error adding new product.";
      if (error.response) {
        if (error.response.status === 401) {
          logout();
        } else {
          errorMessage = error.response.data.message || errorMessage;
        }
      }

      setShowAddProductModal(false);
      toast.error(errorMessage);
    }
  };

  useEffect(() => {}, [sections]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    if (selectedProduct) {
      handleFetchedSections(selectedProduct.product_id);
    }
  }, [selectedProduct, handleFetchedSections]);

  const moveItem = (sectionId, dragIndex, hoverIndex) => {
    if (dragIndex === undefined) {
      return;
    }
    setSections((prevSections) => {
      const sectionIndex = prevSections.findIndex(
        (section) => section.id === sectionId
      );
      if (sectionIndex === -1) return prevSections;

      const section = prevSections[sectionIndex];
      const dragItem = section.items[dragIndex];

      const newItems = [...section.items];
      newItems.splice(dragIndex, 1);
      newItems.splice(hoverIndex, 0, dragItem);

      const newSection = {
        ...section,
        items: newItems.map((item, index) => ({ ...item, index })),
      };

      const newSections = [...prevSections];
      newSections.splice(sectionIndex, 1, newSection);

      return newSections;
    });
  };

  const handleItemDrop = (sectionId, itemId) => {
    setSections((prevSections) => {
      const sectionIndex = prevSections.findIndex(
        (section) => section.id === sectionId
      );
      if (sectionIndex === -1) return prevSections;

      const section = prevSections[sectionIndex];
      const itemIndex = section.items.findIndex((item) => item.id === itemId);

      // Add new item to the section
      if (itemIndex === -1) {
        const newItem = items.find((item) => item.id === itemId);
        if (!newItem) return prevSections; // Exit if item not found in global list

        // Insert the new item either at the start or end based on user's drop
        const newItems = [...section.items];
        newItems.push({
          ...newItem,
          index: newItems.length,
          verify_prev: section.items.length > 0,
        }); // Or unshift() to add at start

        // Update items with new indexes
        const updatedItems = newItems.map((item, index) => ({
          ...item,
          index,
        }));

        return prevSections.map((sec, idx) =>
          idx === sectionIndex ? { ...sec, items: updatedItems } : sec
        );
      }
      // If item is already in the section, nothing needs to be done
      return prevSections;
    });

    setHasUnsavedChanges(true);
  };
  const handleItemCheckedChange = (itemId, currentVerifyPrev) => {
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        items: section.items.map((item) =>
          item.id === itemId
            ? { ...item, verify_prev: !currentVerifyPrev }
            : item
        ),
      }))
    );
    setHasUnsavedChanges(true);
  };

  const handleCollectSerialChange = (itemId, newValue) => {
    setSections((prevSections) =>
      prevSections.map((section) => ({
        ...section,
        items: section.items.map((item) =>
          item.id === itemId
            ? { ...item, collect_serial: newValue } // update the boolean
            : item
        ),
      }))
    );
    setHasUnsavedChanges(true);
  };

  const handleItemRemove = (sectionId, itemId) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId
          ? {
              ...section,
              items: section.items
                .filter((item) => item.id !== itemId)
                .map((item, index) => ({ ...item, index })),
            }
          : section
      )
    );
    setHasUnsavedChanges(true);
  };

  const handleRemoveProcess = (itemId, processName) => {
    if (isItemInSection(itemId)) {
      // Process is part of a route, show error message
      showAlert(
        true,
        "Error",
        `Cannot delete ${processName} as it is part of a route.`,
        () => {}, // No action on OK
        "alert"
      );
    } else {
      // Process is not part of a route, show confirmation
      showAlert(
        true,
        "Delete Process",
        `Are you sure you want to delete ${processName}?`,
        async (proceed) => {
          if (proceed) {
            await removeProcess(itemId, processName); // Call the function to remove process
          }
        },
        "confirm",
        "Yes, Delete",
        "Cancel"
      );
    }
  };

  const removeProcess = async (processId, processName) => {
    try {
      // Replace with your actual API call to delete the process
      const response = await axios.delete(
        `${SERVER_URL}/HC/api/deleteProcess.php`,
        {
          data: { processId },
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        toast.success(`Process ${processName} deleted successfully`);
        fetchItems(); // Refresh the process list
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      let errorMessage = "Error deleting process.";
      if (error.response && error.response.status === 401) {
        logout();
      } else {
        errorMessage = error.response
          ? error.response.data.message
          : errorMessage;
        toast.error(errorMessage);
      }
    }
  };

  const updateItems = (sectionId, newItems) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId ? { ...section, items: newItems } : section
      )
    );
  };

  return (
    <div className="ProductRoutes">
      <JoyrideToggle steps={steps} />
      <DndProvider backend={HTML5Backend}>
        <h1>Route Mapper</h1>
        <div className="container">
          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            bodyClassName="center-toast"
          />
          <div className="item-list">
            <h2>Process</h2>
            <div className="filter-add-container">
              <input
                type="text"
                id="process-filter-input"
                placeholder="Filter processes..."
                value={filterText}
                onChange={handleFilterChange}
                className="filter-input"
              />
              <button
                className="button add-process-button"
                onClick={() => setIsAddProcessModalOpen(true)}
              >
                <AiOutlinePlus className="icon" />
                Add Process
              </button>
            </div>
            <AddProcessModal
              isOpen={isAddProcessModalOpen}
              onRequestClose={() => setIsAddProcessModalOpen(false)}
              onSubmit={handleAddProcessSubmit}
            />
            {filteredItems.map(
              (item) =>
                !isItemInSection(item.id) && (
                  <Item
                    key={item.id}
                    id={item.id}
                    process={item.process}
                    moveItem={moveItem}
                    submitProcessNameChange={submitProcessNameChange} // Pass submitProcessNameChange
                    onRemoveProcess={() =>
                      handleRemoveProcess(item.id, item.process)
                    }
                  />
                )
            )}
          </div>
          <div className="sections">
            <h2>Products</h2>
            <div className="product-dropdown-container">
              <Select
                options={productOptions}
                value={selectedOption} // Control the selected value
                onChange={handleProductSelectChange}
                className="product-select"
                placeholder="Select a product"
                isSearchable={true}
              />
              <label>
                <input
                  type="checkbox"
                  checked={showArchived}
                  onChange={handleArchivedChange}
                />
                <span class="checkboxtext">Include Archived Products</span>
              </label>
              <button
                className="button clone-product-button"
                onClick={() => setShowCloneProductModal(true)}
              >
                <IoDuplicateOutline className="icon" />
                Clone Product
              </button>

              <button
                class="button add-product-button"
                onClick={() => setShowAddProductModal(true)}
              >
                <AiOutlinePlus className="icon" />
                Add New Product
              </button>
            </div>
            <AddProductModal
              isOpen={showAddProductModal}
              onRequestClose={() => setShowAddProductModal(false)}
              onSubmit={handleAddProductSubmit}
            />
            <EditProductModal
              isOpen={showEditProductModal}
              onRequestClose={() => setShowEditProductModal(false)}
              onSubmit={handleEditProductSubmit}
              product={editingProduct}
            />
            <CloneProductModal
              isOpen={showCloneProductModal}
              onRequestClose={() => setShowCloneProductModal(false)}
              onSubmit={handleCloneProductSubmit}
              initialData={selectedProduct}
            />

            {selectedProduct &&
              sections.map((section, index) => (
                <Section
                  key={`${section.id}-${index}`} // Add index to key
                  id={section.id}
                  name={section.name}
                  items={section.items}
                  onItemDrop={handleItemDrop}
                  onItemRemove={handleItemRemove}
                  updateItems={updateItems}
                  moveItem={moveItem}
                  onItemCheckedChange={handleItemCheckedChange}
                  submitProcessNameChange={submitProcessNameChange}
                  onEditProduct={handleEditProductClick}
                  onDeleteProduct={handleDeleteProductClick}
                  onUnarchiveProduct={handleUnarchiveProduct}
                  product={products.find((p) => p.product_id === section.id)}
                  isArchived={section.isArchived}
                  onCollectSerialChange={handleCollectSerialChange}
                />
              ))}
            {selectedProduct && (
              <PublishSection submitData={() => submitData(false)} />
            )}
          </div>
        </div>
      </DndProvider>
    </div>
  );
}

export default ProductRoutes;
