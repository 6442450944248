import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Progress,
  Spin,
  Input,
  Button,
  Space,
  DatePicker,
  Slider,
  Tooltip,
  Tag,
} from "antd";
import {
  SearchOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  ClockCircleOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import SerialRouteChart from "./SerialRouteChart";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import { SERVER_URL } from "../utils/constants";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import "../assets/styles/SerialTracker.css";

dayjs.extend(isBetween);
dayjs.extend(relativeTime);

const { RangePicker } = DatePicker;

const SerialTracker = ({ serialsData = [] }) => {
  const { user, logout } = useAuth();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [serialDetails, setSerialDetails] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30", "50", "100"],
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [progressRange, setProgressRange] = useState([0, 100]);

  let searchInput;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleProgressRangeChange = (range) => {
    setProgressRange(range);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? <span>{text}</span> : text,
  });

  const getColumnDateRangeProps = (dataIndex) => ({
    filterDropdown: ({ confirm }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          onChange={(dates) => {
            handleDateRangeChange(dates);
            confirm();
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (!dateRange[0] || !dateRange[1]) return true;
      const startDate = dayjs(dateRange[0]);
      const endDate = dayjs(dateRange[1]);
      return dayjs(record[dataIndex]).isBetween(startDate, endDate, null, "[]");
    },
    render: (text) => (
      <span>
        <ClockCircleOutlined style={{ color: "#fa541c" }} />{" "}
        {dayjs(text).fromNow()}
      </span>
    ),
  });

  const renderChildSerials = (childSerialsInfo) => {
    if (!childSerialsInfo || childSerialsInfo === "N/A") {
      return <Tag color="default">No attached serials</Tag>;
    }

    const childSerials = childSerialsInfo.split("|").map((item) => {
      const [childType, serial] = item.split(": ");
      return (
        <Tooltip title={childType} key={serial}>
          <Tag color="blue" style={{ marginBottom: "4px" }}>
            {serial}
          </Tag>
        </Tooltip>
      );
    });

    return <div>{childSerials}</div>;
  };

  const columns = [
    {
      title: "Work Order",
      dataIndex: "workorder",
      key: "workorder",
      className: "workorder-column",
      sorter: (a, b) => a.workorder.localeCompare(b.workorder),
      ...getColumnSearchProps("workorder"),
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      className: "product-column",
      sorter: (a, b) => a.product.localeCompare(b.product),
      ...getColumnSearchProps("product"),
    },
    {
      title: "Serial",
      dataIndex: "serial",
      key: "serial",
      className: "serial-column",
      sorter: (a, b) => a.serial.localeCompare(b.serial),
      ...getColumnSearchProps("serial"),
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      className: "progress-column",
      sorter: (a, b) => a.progress - b.progress,
      render: (progress, record) => {
        const formattedProgress = parseFloat(progress).toFixed(2);
        const isScrapped = record.isScrapped === "1";
        const isComplete = record.completeStage === 1;

        if (isScrapped) {
          return (
            <Tooltip title="This serial has been scrapped">
              <Progress
                percent={formattedProgress}
                status="exception"
                size="small"
                strokeColor="red"
                format={() => (
                  <span style={{ color: "red" }}>
                    <WarningOutlined />
                  </span>
                )}
              />
            </Tooltip>
          );
        } else if (isComplete) {
          return (
            <Tooltip title="All stages complete">
              <Progress
                percent={100}
                size="small"
                status="success"
                format={() => (
                  <span style={{ color: "green" }}>
                    <CheckCircleOutlined /> Complete
                  </span>
                )}
              />
            </Tooltip>
          );
        } else {
          return <Progress percent={formattedProgress} size="small" />;
        }
      },
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <Slider
            range
            value={progressRange}
            onChange={handleProgressRangeChange}
            onAfterChange={() => {
              confirm();
            }}
            min={0}
            max={100}
            style={{ width: 200 }}
          />
        </div>
      ),
      onFilter: (value, record) => {
        return (
          record.progress >= progressRange[0] &&
          record.progress <= progressRange[1]
        );
      },
    },
    {
      title: "Last Entry Time",
      dataIndex: "lastEntryTime",
      key: "lastEntryTime",
      sorter: (a, b) =>
        dayjs(a.lastEntryTime).unix() - dayjs(b.lastEntryTime).unix(),
      ...getColumnDateRangeProps("lastEntryTime"),
    },
    {
      title: "Additional Serials",
      dataIndex: "childSerialsInfo",
      key: "childSerialsInfo",
      ...getColumnSearchProps("childSerialsInfo"),
      render: (childSerialsInfo) => renderChildSerials(childSerialsInfo),
    },
  ];

  const fetchSerialDetails = useCallback(
    async (serial, product, workOrder) => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/HC/api/serial_details.php`,
          {
            headers: {
              Authorization: user.token,
              PrivateCode: user.privateCode,
            },
            params: {
              serial,
              product,
              workOrder,
            },
          }
        );
        return response.data.records;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          logout();
        }
        console.error("Error fetching serial details:", error);
        throw error;
      }
    },
    [user.token, user.privateCode, logout]
  );

  const handleExpand = async (expanded, record) => {
    if (expanded) {
      try {
        const details = await fetchSerialDetails(
          record.serial,
          record.product,
          record.workorder
        );
        setSerialDetails((prevDetails) => ({
          ...prevDetails,
          [`${record.serial}-${record.product}-${record.workorder}`]: details,
        }));
      } catch (error) {
        console.error("Error expanding row:", error);
      }
    }
    setExpandedRowKeys(
      expanded
        ? [...expandedRowKeys, record.key]
        : expandedRowKeys.filter((key) => key !== record.key)
    );
  };

  const expandIcon = ({ expanded, onExpand, record }) => {
    const workorderClass = record.workorder.replace(/\s+/g, "_");
    const productClass = record.product.replace(/\s+/g, "_");
    const serialClass = record.serial.replace(/\s+/g, "_");

    return (
      <span
        className={`expand-icon ${workorderClass}-${productClass}-${serialClass}`}
        onClick={(e) => onExpand(record, e)}
        style={{
          cursor: "pointer",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        {expanded ? <MinusOutlined /> : <PlusOutlined />}
      </span>
    );
  };

  const expandedRowRender = (record) => (
    <div>
      <SerialRouteChart
        productData={
          serialDetails[
            `${record.serial}-${record.product}-${record.workorder}`
          ] || {}
        }
        serial={record.serial}
      />
    </div>
  );

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Spin size="large" />
      </div>
    );
  }

  const cleanedData = serialsData.map((item) => ({
    ...item,
    serial: item.serial.trim(),
    workorder: item.workorder.trim(),
    product: item.product.trim(),
    lastEntryTime: dayjs(item.lastEntryTime.trim(), "YYYY-MM-DD HH:mm:ss"),
    progress: parseFloat(item.progress || "0"),
  }));

  const uniqueSerialsMap = cleanedData.reduce((acc, item) => {
    const uniqueKey = `${item.workorder}-${item.product}-${item.serial}`;
    if (
      !acc[uniqueKey] ||
      item.lastEntryTime.isAfter(acc[uniqueKey].lastEntryTime)
    ) {
      acc[uniqueKey] = { ...item, key: uniqueKey };
    }
    return acc;
  }, {});

  const dataSource = Object.values(uniqueSerialsMap);

  const filteredDataSource = dataSource.filter((item) => {
    const isDateInRange =
      !dateRange[0] ||
      !dateRange[1] ||
      item.lastEntryTime.isBetween(
        dayjs(dateRange[0]),
        dayjs(dateRange[1]),
        null,
        "[]"
      );

    const isProgressInRange =
      item.progress >= progressRange[0] && item.progress <= progressRange[1];

    return isDateInRange && isProgressInRange;
  });

  return (
    <div className="serial-tracker">
      <h2>Serial Tracker</h2>
      <Table
        columns={columns}
        dataSource={filteredDataSource}
        expandable={{
          expandedRowRender,
          onExpand: handleExpand,
          expandIcon,
          expandedRowKeys,
        }}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default SerialTracker;
