import React, { useState, useEffect } from "react";
import {
  Table,
  Progress,
  Input,
  Button,
  Space,
  Spin,
  DatePicker,
  Slider,
} from "antd";
import { SearchOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import WorkOrderCharts from "./WorkOrderCharts";
import { SERVER_URL } from "../utils/constants";

dayjs.extend(isBetween);
dayjs.extend(relativeTime);

const { RangePicker } = DatePicker;

const WorkOrderOverviewTable = ({
  reportData,
  workOrderDetails,
  setWorkOrderDetails,
  loading,
}) => {
  const { user, logout } = useAuth();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30", "50", "100"],
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [progressRange, setProgressRange] = useState([0, 100]);
  const [filteredData, setFilteredData] = useState(reportData);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleExpand = (expanded, record) => {
    if (
      expanded &&
      !workOrderDetails[`${record.workOrder}-${record.product}`]
    ) {
      fetchWorkOrderDetails(record.workOrder, record.product);
    }
    setExpandedRowKeys(
      expanded
        ? [...expandedRowKeys, `${record.workOrder}-${record.product}`]
        : expandedRowKeys.filter(
            (key) => key !== `${record.workOrder}-${record.product}`
          )
    );
  };

  const expandIcon = ({ expanded, onExpand, record }) => {
    const workorderClass = record.workOrder.replace(/\s+/g, "_");
    const productClass = record.product.replace(/\s+/g, "_");

    return (
      <span
        className={`expand-icon ${workorderClass}-${productClass}`}
        onClick={(e) => onExpand(record, e)}
        style={{
          cursor: "pointer",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        {expanded ? <MinusOutlined /> : <PlusOutlined />}
      </span>
    );
  };

  useEffect(() => {
    let filtered = reportData;

    if (dateRange[0] && dateRange[1]) {
      filtered = filtered.filter((record) => {
        const recordDate = dayjs(record.lastEntryTime);
        return recordDate.isBetween(dateRange[0], dateRange[1], null, "[]");
      });
    }
    filtered = filtered.filter((record) => {
      return (
        record.progress >= progressRange[0] &&
        record.progress <= progressRange[1]
      );
    });

    setFilteredData(filtered);
  }, [dateRange, progressRange, reportData]);

  const fetchWorkOrderDetails = async (workOrder, product) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/HC/api/work_order_details.php`,
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
          params: {
            workOrder,
            product,
          },
        }
      );
      if (response.data && response.data.records) {
        const details = response.data.records;
        setWorkOrderDetails((prevDetails) => ({
          ...prevDetails,
          [`${workOrder}-${product}`]: details,
        }));
      } else {
        setWorkOrderDetails((prevDetails) => ({
          ...prevDetails,
          [`${workOrder}-${product}`]: {},
        }));
      }
    } catch (error) {
      console.error("Error fetching work order details", error);
      setWorkOrderDetails((prevDetails) => ({
        ...prevDetails,
        [`${workOrder}-${product}`]: {},
      }));
      if (error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleProgressRangeChange = (range) => {
    setProgressRange(range);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const columns = [
    {
      title: "Work Order",
      dataIndex: "workOrder",
      key: "workOrder",
      sorter: (a, b) => a.workOrder.localeCompare(b.workOrder),
      ...getColumnSearchProps("workOrder"),
      render: (text, record) => (
        <a
          href="#"
          onClick={() => {
            fetchWorkOrderDetails(record.workOrder, record.product);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      sorter: (a, b) => a.product.localeCompare(b.product),
      ...getColumnSearchProps("product"),
    },
    {
      title: "Total Serials",
      dataIndex: "totalSerials",
      key: "totalSerials",
      sorter: (a, b) => a.totalSerials - b.totalSerials,
    },
    {
      title: "Serials In Progress",
      dataIndex: "serialsInProgress",
      key: "serialsInProgress",
      sorter: (a, b) => a.serialsInProgress - b.serialsInProgress,
    },
    {
      title: "Complete Serials",
      dataIndex: "completeSerials",
      key: "completeSerials",
      sorter: (a, b) => a.completeSerials - b.completeSerials,
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      sorter: (a, b) => a.progress - b.progress,
      render: (progress) => (
        <Progress percent={parseFloat(progress).toFixed(2)} size="small" />
      ),
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <Slider
            range
            value={progressRange}
            onChange={handleProgressRangeChange}
            onAfterChange={(range) => {
              handleProgressRangeChange(range);
              confirm();
            }}
            min={0}
            max={100}
            style={{ width: 200 }}
          />
        </div>
      ),
      onFilter: (value, record) => {
        return (
          record.progress >= progressRange[0] &&
          record.progress <= progressRange[1]
        );
      },
    },
    {
      title: "Last Entry Time",
      dataIndex: "lastEntryTime",
      key: "lastEntryTime",
      sorter: (a, b) => new Date(a.lastEntryTime) - new Date(b.lastEntryTime),
      render: (text) => <span>{dayjs(text).fromNow()}</span>,
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            onChange={(dates) => {
              handleDateRangeChange(dates);
              confirm();
            }}
          />
        </div>
      ),
      onFilter: (value, record) => {
        if (!dateRange[0] || !dateRange[1]) return true;
        const startDate = dayjs(dateRange[0]);
        const endDate = dayjs(dateRange[1]);
        return dayjs(record.lastEntryTime).isBetween(
          startDate,
          endDate,
          null,
          "[]"
        );
      },
    },
  ];

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Spin size="large" tip="Loading data..." />
      </div>
    );
  }

  return (
    <div className="workorder-overview">
      <h1 className="report-title">Work Order Overview</h1>
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey={(record) => `${record.workOrder}-${record.product}`}
        pagination={pagination}
        onChange={handleTableChange}
        expandable={{
          expandedRowRender: (record) =>
            workOrderDetails[`${record.workOrder}-${record.product}`] ? (
              <WorkOrderCharts
                workOrder={record.workOrder}
                product={record.product}
                productData={
                  workOrderDetails[`${record.workOrder}-${record.product}`]
                }
                status={record.status}
              />
            ) : (
              <Spin size="small" />
            ),
          onExpand: handleExpand,
          expandIcon,
          expandedRowKeys,
        }}
      />
    </div>
  );
};

export default WorkOrderOverviewTable;
