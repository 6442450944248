import React, { useState } from "react";
import Modal from "react-modal";
import "../assets/styles/FormModel.css"; // Import the CSS

Modal.setAppElement("#root");

const AddProcessModal = ({ isOpen, onRequestClose, onSubmit }) => {
  const [processName, setProcessName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!processName) {
      alert("Please enter a process name.");
      return;
    }

    onSubmit({ processName });
    setProcessName("");
  };

  return (
    <div className={isOpen ? "form-modal" : ""}>
      {isOpen && (
        <div className="form-modal-content">
          <h2>Add New Process</h2>
          <button className="close-button" onClick={onRequestClose}>
            &times;
          </button>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="process-name">Process Name:</label>
              <input
                type="text"
                name="processName"
                id="process-name"
                className="form-control"
                value={processName}
                onChange={(e) => setProcessName(e.target.value)}
              />
            </div>
            <button type="submit" name="addProcess" className="add-button">
              Add Process
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddProcessModal;
