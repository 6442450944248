import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "../assets/styles/FormModel.css"; // Import the CSS

Modal.setAppElement("#root");

const EditProductModal = ({ isOpen, onRequestClose, onSubmit, product }) => {
  const [productName, setProductName] = useState("");

  useEffect(() => {
    if (product) {
      setProductName(product.product); // Set the current product name
    }
  }, [product]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!productName) {
      alert("Please enter a product name.");
      return;
    }

    onSubmit({ ...product, productName }); // Pass the updated product
    setProductName("");
  };

  return (
    <div className={isOpen ? "form-modal" : ""}>
      {isOpen && (
        <div className="form-modal-content">
          <h2>Edit Product</h2>
          <button className="close-button" onClick={onRequestClose}>
            &times;
          </button>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="product-name">Product Name:</label>
              <input
                type="text"
                id="product-name"
                className="form-control"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>
            <button type="submit" className="edit-button">
              Save Changes
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditProductModal;
