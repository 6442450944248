import React, { useEffect, useState } from "react";
import axios from "axios";
import { SERVER_URL } from "../utils/constants";
import "../assets/styles/FormModel.css"; // Import the CSS
import Modal from "react-modal";

Modal.setAppElement("#root");

const AuditDetailsModal = ({ isOpen, onRequestClose, auditId, user }) => {
  const [details, setDetails] = useState("");

  useEffect(() => {
    const fetchDetails = async () => {
      const res = await axios.get(
        `${SERVER_URL}/HC/api/audit-details.php?audit_id=${auditId}`,
        {
          headers: {
            Authorization: user.token,
            role: user.role,
            userId: user.userId,
            PrivateCode: user.privateCode,
          },
        }
      );
      setDetails(res.data.details);
    };

    fetchDetails();
  }, [auditId, user.token, user.role, user.userId, user.privateCode]);

  return (
    <div className={isOpen ? "audit-details-modal" : ""}>
      {isOpen && (
        <div className="audit-details-modal-content">
          <h2>Audit Details</h2>
          <button className="close-button" onClick={onRequestClose}>
            &times;
          </button>
          <p>{details}</p>
        </div>
      )}
    </div>
  );
};

export default AuditDetailsModal;
