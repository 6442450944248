import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Modal, Table, Alert } from "antd"; // Add Alert from antd

const SerialRouteChart = ({ productData = {} }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalTitle, setModalTitle] = useState("");

  // Check if the process is complete
  const isComplete =
    productData.Complete && productData.Complete.complete_stage === 1;

  const getChartData = () => {
    return Object.keys(productData)
      .filter((stage) => stage !== "Complete") // Remove the 'Complete' stage from the chart data
      .map((stage) => ({
        name: stage,
        "Total Pass": productData[stage].total_pass,
        "Total Fail": productData[stage].total_fail,
        "Total Resolved": productData[stage].total_resolved,
      }));
  };

  const handleBarClick = (data) => {
    const stage = data.name;
    const details = productData[stage].details;

    setModalData(details);
    setModalTitle(`All Records for ${stage}`);
    setModalVisible(true);
  };

  const columns = [
    { title: "Test ID", dataIndex: "test_id", key: "test_id" },
    { title: "Process Name", dataIndex: "process_name", key: "process_name" },
    { title: "Operator Name", dataIndex: "operatorName", key: "operatorName" },
    { title: "Result", dataIndex: "result", key: "result" },
    { title: "Fail Detail", dataIndex: "fail_detail", key: "fail_detail" },
    { title: "Date Entered", dataIndex: "date_entered", key: "date_entered" },
  ];

  return (
    <>
      {/* Render a success alert if the process is complete */}
      {isComplete && (
        <Alert
          message="All stages have been completed successfully."
          type="success"
          showIcon
          style={{ marginBottom: 16 }}
        />
      )}

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={getChartData()}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          onClick={(event) => {
            if (event && event.activePayload) {
              const activePayload = event.activePayload[0];
              handleBarClick(activePayload.payload);
            }
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" angle={-45} textAnchor="end" height={100} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Total Pass" fill="#82ca9d" />
          <Bar dataKey="Total Fail" fill="#ff4d4f" />
          <Bar dataKey="Total Resolved" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>

      <Modal
        visible={modalVisible}
        title={modalTitle}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
        bodyStyle={{ overflowY: "auto", maxHeight: "70vh" }}
      >
        <Table
          columns={columns}
          dataSource={modalData}
          rowKey="test_id"
          pagination={{ pageSize: 5 }}
        />
      </Modal>
    </>
  );
};

export default SerialRouteChart;
