import React, { useState } from "react";
import WorkOrderReport from "../components/WorkOrderReport";
import ShopFloorReport from "../components/ShopFloorReport"; // Import the ShopFloorReport component

const ReportsPage = () => {
  const [selectedReport, setSelectedReport] = useState("shopFloor");

  return (
    <div>
      <h1>Reports Dashboard</h1>
      <p>Welcome to the Reports Page. Here you can view detailed reports.</p>

      {/* Buttons or tabs to select the report */}
      <button class="button" onClick={() => setSelectedReport("shopFloor")}>
        Shop Floor Report
      </button>
      <button class="button" onClick={() => setSelectedReport("workOrder")}>
        Work Order Report
      </button>

      {/* Conditional rendering based on selected report */}
      {selectedReport === "workOrder" && <WorkOrderReport />}
      {selectedReport === "shopFloor" && <ShopFloorReport />}
    </div>
  );
};

export default ReportsPage;
