import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import StatusIndicator from "./StatusIndicator";
import "../assets/styles/WorkOrderReport.css";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { SERVER_URL } from "../utils/constants";

const WorkOrderReport = () => {
  const [workOrders, setWorkOrders] = useState([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState("");
  const [reportData, setReportData] = useState(null);
  const { user, logout } = useAuth();
  const [visibleStages, setVisibleStages] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm] = useState("");
  const [stageFilters] = useState({});
  const [visibleDetails, setVisibleDetails] = useState({});
  const [visibleScrappedDetails, setVisibleScrappedDetails] = useState({});
  const [openAccordion, setOpenAccordion] = useState({});
  const [expandedProducts, setExpandedProducts] = useState({});

  useEffect(() => {
    const fetchWorkOrders = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${SERVER_URL}/HC/api/getWorkOrders.php`,
          {
            headers: {
              Authorization: user.token,
              PrivateCode: user.privateCode,
            },
          }
        );
        if (response.data && Array.isArray(response.data)) {
          setWorkOrders(response.data);
        } else {
          setWorkOrders([]);
        }
      } catch (error) {
        console.error("Error fetching work orders", error);
        setError(error);
        setWorkOrders([]);
        if (error.response && error.response.status === 401) {
          logout(); // Logout on 401 Unauthorized
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchWorkOrders();
    }
  }, [user, logout]);

  useEffect(() => {
    const fetchReportData = async () => {
      if (!selectedWorkOrder) return;

      setIsLoading(true);
      try {
        const response = await axios.get(
          `${SERVER_URL}/HC/api/getReport.php?workOrder=${selectedWorkOrder}`,
          {
            headers: {
              Authorization: user.token,
              PrivateCode: user.privateCode,
            },
          }
        );
        if (response.data) {
          setReportData(response.data[selectedWorkOrder] || {});
        } else {
          setReportData(null);
        }
      } catch (error) {
        console.error("Error fetching report data", error);
        if (error.response && error.response.status === 401) {
          logout();
        } else if (error.response.status === 404) {
          setReportData(null);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (user && selectedWorkOrder) {
      fetchReportData();
    }
  }, [selectedWorkOrder, user, logout]);

  const handleWorkOrderChange = (event) => {
    setSelectedWorkOrder(event.target.value);
  };

  const toggleProductVisibility = (product) => {
    setExpandedProducts((prev) => ({
      ...prev,
      [product]: !prev[product],
    }));
  };

  const toggleStageVisibility = (stage) => {
    setVisibleStages((prev) => ({ ...prev, [stage]: !prev[stage] }));
  };

  const getCurrentStage = (productData, serial) => {
    const stages = Object.keys(productData);
    for (let i = stages.length - 1; i >= 0; i--) {
      const stage = stages[i];
      const stageRecords = productData[stage].filter(
        (record) => record.serial === serial
      );

      if (stageRecords.length > 0) {
        const lastRecord = stageRecords[stageRecords.length - 1];
        if (lastRecord.result === "PASS") {
          return i < stages.length - 1 ? stages[i + 1] : "Complete";
        } else {
          return stage;
        }
      }
    }
    return "Not Started";
  };

  const getAwaitingRecordsAtStage = (productData, stage) => {
    const previousStageIndex = Object.keys(productData).indexOf(stage) - 1;

    if (previousStageIndex < 0) {
      return productData[stage].filter((record) => {
        const atCurrentStage =
          getCurrentStage(productData, record.serial) === stage;
        const notScrapped = !isSerialScrapped(productData, record.serial);
        return record.result === "FAIL" && atCurrentStage && notScrapped;
      });
    }

    const previousStage = Object.keys(productData)[previousStageIndex];
    return productData[previousStage].filter((record) => {
      const passedPreviousStage = record.result === "PASS";
      const atCurrentStage =
        getCurrentStage(productData, record.serial) === stage;
      const notScrapped = !isSerialScrapped(productData, record.serial);
      const notBeyondCurrentStage = !isStageBeyondCurrent(
        productData,
        record.serial,
        stage
      );

      return (
        passedPreviousStage &&
        atCurrentStage &&
        notScrapped &&
        notBeyondCurrentStage
      );
    });
  };

  const isStageBeyondCurrent = (productData, serial, currentStage) => {
    const stages = Object.keys(productData);
    const currentStageIndex = stages.indexOf(currentStage);
    const highestStageIndex = stages.reduce((highestIndex, stage, index) => {
      if (
        productData[stage].some(
          (record) => record.serial === serial && record.result === "PASS"
        )
      ) {
        return index;
      }
      return highestIndex;
    }, -1);

    return highestStageIndex > currentStageIndex;
  };

  const isSerialComplete = (productData, serial) => {
    const lastStage =
      Object.keys(productData)[Object.keys(productData).length - 1];
    return productData[lastStage].some(
      (record) => record.serial === serial && record.result === "PASS"
    );
  };

  const isSerialScrapped = (productData, serial) => {
    return Object.values(productData)
      .flat()
      .some((record) => record.serial === serial && record.result === "SCRAP");
  };

  const getCompleteSerials = (productData) => {
    if (!productData) {
      return [];
    }
    const allSerials = new Set(
      Object.values(productData)
        .flat()
        .map((record) => record.serial)
    );

    return Array.from(allSerials).filter(
      (serial) =>
        isSerialComplete(productData, serial) ||
        isSerialScrapped(productData, serial)
    );
  };

  const getScrappedSerials = (productData) => {
    if (!productData) {
      return [];
    }
    const allSerials = new Set(
      Object.values(productData)
        .flat()
        .map((record) => record.serial)
    );

    return Array.from(allSerials).filter((serial) =>
      isSerialScrapped(productData, serial)
    );
  };

  const calculateProgress = (dateEntered) => {
    const startDate = new Date(dateEntered);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const getFilteredReportData = () => {
    if (!reportData) {
      return {};
    }
    const filteredData = {};

    Object.entries(reportData).forEach(([product, stages]) => {
      filteredData[product] = Object.entries(stages).reduce(
        (acc, [stage, records]) => {
          const isStageVisible = stageFilters[stage] !== false;

          const filteredRecords = records.filter((record) => {
            const matchesSearchTerm =
              record.serial.toLowerCase().includes(searchTerm) ||
              record.operatorName.toLowerCase().includes(searchTerm);

            const isResultFiltered =
              record.result === "PASS" || record.result === "RESOLVED";

            if (stage.includes("Position 0") && record.result === "FAIL") {
              return matchesSearchTerm;
            }

            return matchesSearchTerm && isResultFiltered && isStageVisible;
          });

          if (filteredRecords.length || records.length === 0) {
            acc[stage] = filteredRecords;
          } else {
            acc[stage] = [];
          }

          return acc;
        },
        {}
      );
    });

    return filteredData;
  };

  const getCountAtStage = (productData, stage) => {
    return getAwaitingRecordsAtStage(productData, stage).length;
  };

  const getCountPassedStage = (productData, stage) => {
    return productData[stage]
      ? productData[stage].filter((record) => record.result === "PASS").length
      : 0;
  };

  const getCountGoodComplete = (productData) => {
    return (
      getCompleteSerials(productData).length -
      getScrappedSerials(productData).length
    );
  };

  const getChartData = (productData) => {
    if (!productData) {
      return [];
    }
    const stagesData = [];

    Object.keys(productData).forEach((stage) => {
      const shortName = stage.replace(/ - Position \d+/, "");
      stagesData.push({
        name: shortName,
        "At Stage": getCountAtStage(productData, stage),
        "Passed Stage": getCountPassedStage(productData, stage),
      });
    });

    stagesData.push({
      name: "Good Complete",
      "At Stage": getCountGoodComplete(productData),
      "Passed Stage": 0,
    });

    stagesData.push({
      name: "Scrap",
      "At Stage": getScrappedSerials(productData).length,
      "Passed Stage": 0,
    });

    return stagesData;
  };

  const getPieChartData = (productData) => {
    const dataWithCounts = getChartData(productData).map((item) => {
      return { name: item.name, value: item["At Stage"] };
    });

    return dataWithCounts;
  };

  const COLORS = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff8042",
    "#a4de6c",
    "#d0ed57",
    "#ffc0cb",
    "#6a5acd",
    "#20b2aa",
    "#778899",
    "#c71585",
    "#ff6347",
    "#2e8b57",
    "#4682b4",
    "#b4a7d6",
    "#f08080",
    "#f497a2",
    "#a1d99b",
    "#bcbddc",
    "#9e9ac8",
  ];

  const getUniqueRecordsAtStage = (productData, stage) => {
    const currentStageIndex = Object.keys(productData).indexOf(stage);
    const previousStage =
      currentStageIndex > 0
        ? Object.keys(productData)[currentStageIndex - 1]
        : null;
    const previousStageRecords = previousStage
      ? productData[previousStage]
      : [];
    const currentStageRecords = productData[stage] || [];

    const potentialStageSerials = new Set(
      previousStageRecords
        .filter((record) => record.result === "PASS")
        .map((record) => record.serial)
        .concat(
          currentStageRecords
            .filter((record) => record.result === "FAIL")
            .map((record) => record.serial)
        )
    );

    const serialsAtStage = Array.from(potentialStageSerials).filter(
      (serial) => {
        const hasPassedCurrent = currentStageRecords.some(
          (record) => record.serial === serial && record.result === "PASS"
        );
        const isScrapped = isSerialScrapped(productData, serial);
        return !hasPassedCurrent && !isScrapped;
      }
    );

    return serialsAtStage.map((serial) => {
      const attempts = currentStageRecords.filter(
        (record) => record.serial === serial
      );
      return {
        serial,
        attempts:
          attempts.length > 0
            ? attempts
            : [
                {
                  serial,
                  result: "AWAITING",
                  test_id: null,
                  operatorName: null,
                  date_entered: null,
                },
              ],
      };
    });
  };

  const toggleRecordVisibility = (serial) => {
    setVisibleDetails((prevDetails) => ({
      ...prevDetails,
      [serial]: !prevDetails[serial],
    }));
  };

  const toggleScrappedDetailsVisibility = (serial) => {
    setVisibleScrappedDetails((prevDetails) => ({
      ...prevDetails,
      [serial]: !prevDetails[serial],
    }));
  };

  const handleAccordionClick = (product, stage) => {
    setOpenAccordion((prev) => ({
      ...prev,
      [product]: {
        ...prev[product],
        [stage]: !prev[product]?.[stage],
      },
    }));
  };

  const truncateLabel = (str, maxLength = 13) => {
    return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error fetching data. Please try again later.</div>;
  }

  return (
    <div className="work-order-report">
      <h1 className="report-title">Work Order Report</h1>
      <select value={selectedWorkOrder} onChange={handleWorkOrderChange}>
        <option value="">Select a Work Order</option>
        {workOrders.map((order) => (
          <option key={order.workorder} value={order.workorder}>
            {order.workorder}
          </option>
        ))}
      </select>

      {selectedWorkOrder && reportData === null ? (
        <div>No data found for the selected work order.</div>
      ) : (
        selectedWorkOrder && (
          <div>
            {Object.entries(getFilteredReportData()).map(
              ([product, stages]) => (
                <div key={product}>
                  <h2 onClick={() => toggleProductVisibility(product)}>
                    {product}
                    {expandedProducts[product]
                      ? " (Hide Details)"
                      : " (Show Details)"}
                  </h2>
                  {expandedProducts[product] && (
                    <>
                      {Object.entries(stages).map(([stage, records]) => (
                        <div key={stage} className="stage-section">
                          <h3
                            className="stage-title"
                            onClick={() => handleAccordionClick(product, stage)}
                          >
                            {stage}
                            {openAccordion[product]?.[stage]
                              ? " (Hide Details)"
                              : " (Show Details)"}
                          </h3>

                          {openAccordion[product]?.[stage] && (
                            <div>
                              <div className="records passed">
                                <h4>
                                  Passed Stage - Total:{" "}
                                  {
                                    records.filter(
                                      (record) => record.result === "PASS"
                                    ).length
                                  }
                                </h4>
                                {records
                                  .filter((record) => record.result === "PASS")
                                  .map((record) => (
                                    <div
                                      key={`${record.serial}-${record.test_id}`}
                                    >
                                      <strong>Serial:</strong> {record.serial} |
                                      <strong>Test ID:</strong> {record.test_id}{" "}
                                      |<strong>Operator Name:</strong>{" "}
                                      {record.operatorName} |
                                      <strong>Result:</strong>{" "}
                                      <StatusIndicator status={record.result} />
                                    </div>
                                  ))}
                              </div>

                              <div className="records at-stage">
                                <h4>
                                  At Stage - Total:{" "}
                                  {
                                    getUniqueRecordsAtStage(stages, stage)
                                      .length
                                  }
                                </h4>
                                {getUniqueRecordsAtStage(stages, stage).map(
                                  (entry) => (
                                    <div key={entry.serial}>
                                      <strong>Serial:</strong> {entry.serial}
                                      {entry.attempts.length > 0 &&
                                        entry.attempts[0].test_id && (
                                          <button
                                            onClick={() =>
                                              toggleRecordVisibility(
                                                entry.serial
                                              )
                                            }
                                          >
                                            {visibleDetails[entry.serial]
                                              ? "Hide Attempts"
                                              : "Show Attempts"}
                                          </button>
                                        )}
                                      {visibleDetails[entry.serial] && (
                                        <div className="attempts">
                                          {entry.attempts.map(
                                            (attempt, index) => (
                                              <div key={index}>
                                                {attempt.test_id && (
                                                  <>
                                                    <strong>Test ID:</strong>{" "}
                                                    {attempt.test_id} |
                                                    <strong>
                                                      Operator Name:
                                                    </strong>{" "}
                                                    {attempt.operatorName} |
                                                    <strong>Result:</strong>{" "}
                                                    <StatusIndicator
                                                      status={attempt.result}
                                                    />
                                                    {attempt.result !==
                                                      "PASS" &&
                                                      ` was added ${calculateProgress(
                                                        attempt.date_entered
                                                      )} days ago`}
                                                  </>
                                                )}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      <div>
                        <h3 onClick={() => toggleStageVisibility("Complete")}>
                          Complete - Total Records:{" "}
                          {getCompleteSerials(stages).length} (Good Complete:{" "}
                          {getCompleteSerials(stages).length -
                            getScrappedSerials(stages).length}
                          , Scrap: {getScrappedSerials(stages).length})
                          {visibleStages["Complete"] ? " (Hide)" : " (Show)"}
                        </h3>
                        {visibleStages["Complete"] && (
                          <div>
                            {getCompleteSerials(stages).map((serial) => {
                              const isScrapped = isSerialScrapped(
                                stages,
                                serial
                              );
                              const className = isScrapped
                                ? "scrapped-serial"
                                : "good-serial";
                              return (
                                <div key={serial} className={className}>
                                  <strong>Serial:</strong> {serial}
                                  {isScrapped && " (Scrapped)"}
                                  {isScrapped && (
                                    <button
                                      onClick={() =>
                                        toggleScrappedDetailsVisibility(serial)
                                      }
                                    >
                                      {visibleScrappedDetails[serial]
                                        ? "Hide Details"
                                        : "Show Details"}
                                    </button>
                                  )}
                                  {visibleScrappedDetails[serial] &&
                                    isScrapped && (
                                      <div>
                                        {Object.entries(reportData).map(
                                          ([stage, records]) =>
                                            records
                                              .filter(
                                                (record) =>
                                                  record.serial === serial &&
                                                  record.result === "SCRAP"
                                              )
                                              .map((record, index) => (
                                                <div
                                                  key={`${record.serial}-${record.test_id}`}
                                                >
                                                  <strong>Serial:</strong>{" "}
                                                  {record.serial} |
                                                  <strong>Test ID:</strong>{" "}
                                                  {record.test_id} |
                                                  <strong>
                                                    Operator Name:
                                                  </strong>{" "}
                                                  {record.operatorName} |
                                                  <strong>Result:</strong>{" "}
                                                  <StatusIndicator
                                                    status={record.result}
                                                  />
                                                </div>
                                              ))
                                        )}
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <div>
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart
                            width={1000}
                            height={500}
                            data={getChartData(stages)}
                            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="name"
                              angle={-45}
                              textAnchor="end"
                              height={100}
                              tickFormatter={(tick) => truncateLabel(tick)}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="At Stage" fill="#8884d8" />
                            <Bar dataKey="Passed Stage" fill="#82ca9d" />
                          </BarChart>
                        </ResponsiveContainer>

                        <ResponsiveContainer width="100%" height={400}>
                          <LineChart
                            data={getChartData(stages)}
                            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="name"
                              tickFormatter={(tick) => truncateLabel(tick)}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="At Stage"
                              stroke="#8884d8"
                            />
                            <Line
                              type="monotone"
                              dataKey="Passed Stage"
                              stroke="#82ca9d"
                            />
                          </LineChart>
                        </ResponsiveContainer>

                        <ResponsiveContainer width="100%" height={400}>
                          <PieChart>
                            <Pie
                              data={getPieChartData(stages)}
                              cx="50%"
                              cy="50%"
                              outerRadius={150}
                              label
                            >
                              {getPieChartData(stages).map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              ))}
                            </Pie>
                            <Tooltip />
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </>
                  )}
                </div>
              )
            )}
          </div>
        )
      )}
    </div>
  );
};

export default WorkOrderReport;
