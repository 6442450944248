import React, { useState } from "react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Button, Collapse, Table, Alert, Spin } from "antd";
import StatusIndicator from "./StatusIndicator";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { SERVER_URL } from "../utils/constants";

const { Panel } = Collapse;

const WorkOrderCharts = ({ workOrder, product, productData, status }) => {
  const [view, setView] = useState("bar"); // default to bar chart
  const [selectedStage, setSelectedStage] = useState(null);
  const [stageDetails, setStageDetails] = useState({
    atStage: {},
    completeStage: {},
  });
  const [loadingDetails, setLoadingDetails] = useState(false);
  const { user, logout } = useAuth();

  const COLORS = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff8042",
    "#a4de6c",
    "#d0ed57",
    "#ffc0cb",
    "#6a5acd",
    "#20b2aa",
    "#778899",
    "#c71585",
    "#ff6347",
    "#2e8b57",
    "#4682b4",
    "#b4a7d6",
    "#f08080",
    "#f497a2",
    "#a1d99b",
    "#bcbddc",
    "#9e9ac8",
  ];

  const fetchStageDetails = async (workOrder, product, stage) => {
    setLoadingDetails(true);
    try {
      const response = await axios.get(
        `${SERVER_URL}/HC/api/stage_details.php`,
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
          params: {
            workOrder,
            product,
            stage,
          },
        }
      );
      setStageDetails(response.data);
    } catch (error) {
      console.error("Error fetching stage details", error);
      if (error.response && error.response.status === 401) {
        logout();
      }
      setStageDetails({ atStage: {}, completeStage: {} });
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleBarClick = async (data) => {
    setSelectedStage(data.name);
    await fetchStageDetails(workOrder, product, data.name);
  };

  const getChartData = (productData) => {
    if (!productData) {
      return [];
    }
    const stagesData = Object.keys(productData).map((stage) => ({
      name: stage,
      "At Stage": productData[stage]["at_stage"] || 0,
      "Complete Stage": productData[stage]["complete_stage"] || 0,
    }));

    return stagesData;
  };

  const renderStageDetails = (stage, atStageRecords, completeStageRecords) => {
    if (loadingDetails) {
      return <Spin size="large" />;
    }

    const atStageData = Object.keys(atStageRecords).map((serial) => ({
      serial,
      records: atStageRecords[serial],
    }));

    const completeStageData = Object.keys(completeStageRecords).map(
      (serial) => ({
        serial,
        records: completeStageRecords[serial],
      })
    );

    const columns = [
      {
        title: "Serial",
        dataIndex: "serial",
        key: "serial",
        render: (text, record) => (
          <Collapse>
            <Panel header={text} key={text}>
              <Table
                columns={[
                  {
                    title: "Operator Name",
                    dataIndex: "operatorName",
                    key: "operatorName",
                  },
                  {
                    title: "Result",
                    dataIndex: "result",
                    key: "result",
                    render: (result) => <StatusIndicator status={result} />,
                  },
                  {
                    title: "Date Entered",
                    dataIndex: "date_entered",
                    key: "date_entered",
                  },
                  {
                    title: "Fail Detail",
                    dataIndex: "fail_detail",
                    key: "fail_detail",
                  },
                ]}
                dataSource={record.records}
                rowKey={(r) => r.test_id}
                pagination={false}
              />
            </Panel>
          </Collapse>
        ),
      },
    ];

    return (
      <div className="details">
        <div className="completed-serials">
          <h3>Completed Serials for {stage}</h3>
          {completeStageData.length > 0 ? (
            <Table
              columns={columns}
              dataSource={completeStageData}
              rowKey={(record) => record.serial}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30", "50"],
                showQuickJumper: true,
              }}
            />
          ) : (
            <p>No completed serials for this stage.</p>
          )}
        </div>

        <div className="serials-at-stage">
          <h3>Serials at Stage {stage}</h3>
          {atStageData.length > 0 ? (
            <Table
              columns={columns}
              dataSource={atStageData}
              rowKey={(record) => record.serial}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30", "50"],
                showQuickJumper: true,
              }}
            />
          ) : (
            <p>No serials at this stage.</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      {status === "Complete" && (
        <Alert
          message="This work order is marked as complete."
          type="success"
          showIcon
        />
      )}

      <Button onClick={() => setView("bar")}>Bar Chart</Button>
      <Button onClick={() => setView("line")}>Line Chart</Button>
      <Button onClick={() => setView("pie")}>Pie Chart</Button>

      {view === "bar" && (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={1000}
            height={500}
            data={getChartData(productData)}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            onClick={(data) => handleBarClick(data.activePayload[0].payload)}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" angle={-45} textAnchor="end" height={100} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="At Stage" fill="#8884d8" className="bar-at-stage">
              {getChartData(productData).map((entry, index) => {
                const stageClass = entry.name
                  .replace(/\s+/g, "_")
                  .toLowerCase();
                return (
                  <Cell
                    key={`at-stage-${index}`}
                    className={`bar-at-stage-${stageClass}`}
                  />
                );
              })}
            </Bar>
            <Bar
              dataKey="Complete Stage"
              fill="#82ca9d"
              className="bar-complete-stage"
            >
              {getChartData(productData).map((entry, index) => {
                const stageClass = entry.name
                  .replace(/\s+/g, "_")
                  .toLowerCase();
                return (
                  <Cell
                    key={`complete-stage-${index}`}
                    className={`bar-complete-stage-${stageClass}`}
                  />
                );
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}

      {view === "line" && (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={getChartData(productData)}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            onClick={(data) => handleBarClick(data.activePayload[0].payload)}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="At Stage" stroke="#8884d8" />
            <Line type="monotone" dataKey="Complete Stage" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      )}

      {view === "pie" && (
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <Pie
              data={getChartData(productData).map((item) => ({
                name: item.name,
                value: item["At Stage"] + item["Complete Stage"],
              }))}
              cx="50%"
              cy="50%"
              outerRadius={150}
              label
              onClick={(data) => handleBarClick(data.payload)}
            >
              {getChartData(productData).map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      )}

      {selectedStage && (
        <>
          {renderStageDetails(
            selectedStage,
            stageDetails.atStage,
            stageDetails.completeStage
          )}
        </>
      )}
    </div>
  );
};

export default WorkOrderCharts;
