import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import JoyrideToggle from "../components/JoyRideToggle";

const LoginPage = () => {
  const [operatorName, setOperatorName] = useState("");
  const [password, setPassword] = useState("");
  const [privateCode, setPrivateCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(operatorName, password, privateCode);
      navigate("/");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const steps = [
    { target: ".private-code-input", content: "Enter your private code here." },
    {
      target: ".operator-name-input",
      content: "Enter your operator name here.",
    },
    { target: ".password-input", content: "Enter your password here." },
    { target: ".login-button", content: "Click here to login." },
  ];

  return (
    <div>
      <h1>Login</h1>

      {/* Floating Joyride Toggle */}
      <JoyrideToggle steps={steps} />

      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <form onSubmit={handleSubmit}>
        <label>
          Private Code:
          <input
            name="privateCode"
            type="text"
            value={privateCode}
            onChange={(e) => setPrivateCode(e.target.value)}
            className="private-code-input"
          />
        </label>
        <label>
          Operator Name:
          <input
            type="text"
            name="operatorName"
            value={operatorName}
            onChange={(e) => setOperatorName(e.target.value)}
            className="operator-name-input"
          />
        </label>
        <label>
          Password:
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="password-input"
          />
        </label>
        <button className="button login-button" type="submit">
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
