import React from "react";
import { useForm } from "react-hook-form";
import "../assets/styles/FormModel.css";
import { useAuth } from "../contexts/AuthContext";
import { SERVER_URL } from "../utils/constants";
import axios from "axios";

function AddUserModal({ roles, closeAddUserModal, setUsers, onUserAdded }) {
  const { register, handleSubmit } = useForm();
  const { user, logout } = useAuth();

  const handleNewUserSubmit = async (newUserData) => {
    if (
      !newUserData.operatorName ||
      !newUserData.password ||
      !newUserData.roleId
    ) {
      return;
    }

    try {
      const response = await axios.post(
        `${SERVER_URL}/HC/api/createUser.php`,
        JSON.stringify(newUserData),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
        }
      );

      const data = response.data; // Axios wraps the response data in the data property
      if (data.message) {
        onUserAdded(data); // Use callback to pass data back to UserManager
      } else {
        throw new Error(data.error || "Failed to create user.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout(); // Handle 401 error by logging out the user
      }
      console.error("Error creating user:", error);
      onUserAdded({ error: error.message || "Network or server error" }); // Pass error back to UserManager
    }
  };

  return (
    <div className="form-modal">
      <div className="form-modal-content">
        <button className="close-button" onClick={closeAddUserModal}>
          X
        </button>
        <form onSubmit={handleSubmit(handleNewUserSubmit)}>
          <h2>Add User</h2>
          <label htmlFor="operatorName">Operator Name:</label>
          <input
            type="text"
            id="operatorName"
            name="operatorName"
            {...register("operatorName", { required: true })}
          />
          <br />
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            {...register("password", { required: true })}
          />
          <br />
          <label htmlFor="roleId">Role:</label>
          <select
            id="roleId"
            name="roleId"
            {...register("roleId", { required: true })}
          >
            <option value="">Select Role</option>
            {roles.map((role) => (
              <option key={role.id} value={role.id}>
                {role.rolename}
              </option>
            ))}
          </select>
          <br />
          <button className="button" type="submit">
            Add User
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddUserModal;
