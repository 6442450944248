import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
} from "react-icons/ai";

const Item = React.memo(
  ({
    id,
    process,
    collect_serial,
    onCollectSerialChange,
    onRemoveProcess,
    onRemove,
    isInSection,
    verify_prev,
    onCheckedChange,
    moveItem,
    sectionId,
    moveItemUp,
    moveItemDown,
    submitProcessNameChange,
    index,
    totalItems,
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newProcessName, setNewProcessName] = useState(process);
    const ref = useRef(null);

    const [, drop] = useDrop({
      accept: "item",
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        if (dragIndex === undefined) {
          return;
        }
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the item's height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Time to actually perform the action
        moveItem(sectionId, dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally, it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
    });

    const [, drag] = useDrag({
      type: "item",
      item: () => {
        return { id, index };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    const handleEdit = () => setIsEditing(true);

    const handleSave = async () => {
      setIsEditing(false);
      await submitProcessNameChange(id, newProcessName);
    };

    const handleCancel = () => {
      setIsEditing(false);
      setNewProcessName(process); // Reset the name to original
    };

    drag(ref);

    const itemCount = totalItems; // Total number of items in the section
    const isFirstItem = index === 0; // Is it the first item?
    const isLastItem = index === itemCount - 1; // Is it the last item?
    const isOnlyItem = isFirstItem && isLastItem; // Is it the only item in the section?

    // For items in between, the checkbox status depends on !verify_prev value.
    // For the first and the only item, it's always checked. For the last item, it's always unchecked.
    const checkedStatus =
      isFirstItem || isOnlyItem ? true : !isLastItem && !verify_prev;

    // The checkbox is disabled for the first and the last items, or if it's the only item in the section.
    const disabledStatus = isFirstItem || isLastItem || isOnlyItem;

    return (
      <div ref={ref} className="item-container">
        <div
          className="item"
          id={process}
          style={{ opacity: isEditing ? 0.5 : 1 }}
        >
          {isInSection && (
            <span style={{ display: "none" }} className="item-position">
              #{index + 1}
            </span>
          )}
          {isEditing ? (
            <>
              <input
                value={newProcessName}
                type="text"
                onChange={(e) => setNewProcessName(e.target.value)}
              />
              <button onClick={handleSave} className="save-button">
                Save
              </button>
              <button onClick={handleCancel} className="cancel-button">
                Cancel
              </button>
            </>
          ) : (
            <>
              <span>{process}</span>
              <button class="item-icon rename-button" onClick={handleEdit}>
                <AiOutlineEdit /> {/* Edit Icon */}
              </button>
              {isInSection && (
                <label>
                  <input
                    type="checkbox"
                    checked={checkedStatus}
                    onChange={() => onCheckedChange(id, verify_prev)}
                    className="verify-previous"
                    disabled={disabledStatus}
                  />
                  Skip Verify Previous
                </label>
              )}
              {isInSection && (
                <label>
                  <input
                    type="checkbox"
                    checked={collect_serial}
                    onChange={() => onCollectSerialChange(id, !collect_serial)}
                    className="collect-serial"
                  />
                  Collect Serial Number
                </label>
              )}
              {/* Delete Process Entirely (Only when we are not attached to a product) */}
              {onRemoveProcess && (
                <button
                  onClick={onRemoveProcess}
                  className="item-icon  remove-item"
                >
                  <AiOutlineDelete /> {/* Delete Icon */}
                </button>
              )}

              {onRemove && (
                <button onClick={onRemove} className="item-icon  remove-item">
                  <AiOutlineDelete /> {/* Delete Icon */}
                </button>
              )}
              {moveItemUp && (
                <button onClick={moveItemUp} className="item-icon move-item-up">
                  <AiOutlineArrowUp /> {/* Move Up Icon */}
                </button>
              )}
              {moveItemDown && (
                <button
                  onClick={moveItemDown}
                  className="item-icon move-item-down"
                >
                  <AiOutlineArrowDown /> {/* Move Down Icon */}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);

export default Item;
