// SummaryCards.js
import React from "react";
import { Card, Col, Row } from "antd";
import {
  DollarOutlined,
  ShoppingCartOutlined,
  CheckCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

const SummaryCards = ({
  totalWorkOrders,
  totalProducts,
  totalCompleteOrders,
  totalInProgressOrders,
}) => {
  return (
    <div className="summary-cards">
      <h1 className="report-title">Stats</h1>
      <Row gutter={16}>
        <Col span={6}>
          <Card>
            <DollarOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
            <h2>{totalWorkOrders}</h2>
            <p>Total Work Orders</p>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <ShoppingCartOutlined
              style={{ fontSize: "24px", color: "#52c41a" }}
            />
            <h2>{totalProducts}</h2>
            <p>Total Products</p>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <CheckCircleOutlined
              style={{ fontSize: "24px", color: "#faad14" }}
            />
            <h2>{totalCompleteOrders}</h2>
            <p>Total Complete Orders</p>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <SyncOutlined style={{ fontSize: "24px", color: "#eb2f96" }} />
            <h2>{totalInProgressOrders}</h2>
            <p>Total In Progress Orders</p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SummaryCards;
