import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "../sidebar.css";
import { useAuth } from "../contexts/AuthContext";
import {
  AiOutlineMenu,
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineDatabase,
  // AiOutlineAudit,
} from "react-icons/ai";
import logo from "../assets/images/hc.png";

function LogoutButton() {
  const { logout } = useAuth();

  return <button onClick={logout}>Logout</button>;
}

function Sidebar({ hasUnsavedChanges, handleLinkClick }) {
  const [isShown, setIsShown] = useState(false);
  const { user } = useAuth();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const toggleSidebar = () => {
    setIsShown(!isShown);
  };

  const handleClick = (e) => {
    handleLinkClick(e);
    if (isMobile) toggleSidebar(); // Ensure the sidebar only closes on mobile devices
  };

  return (
    <div>
      {isMobile && (
        <button
          style={{ position: "absolute", top: "10px", left: "10px" }}
          onClick={toggleSidebar}
        >
          <AiOutlineMenu size={24} />
        </button>
      )}
      <div className={`Sidebar ${isMobile ? (isShown ? "show" : "") : "show"}`}>
        {isMobile && (
          <button
            style={{ position: "absolute", top: "10px", right: "10px" }}
            onClick={toggleSidebar}
          >
            <AiOutlineMenu size={24} />
          </button>
        )}
        <div className="scrollable-area">
          {user && (
            <div className="SidebarHeader">
              {user && (
                <div className="UserInfo">
                  <img
                    src={logo}
                    alt="Company Logo"
                    style={{ maxWidth: "100px", margin: "20px 0" }}
                  />{" "}
                  <h3>{user.username}</h3>
                  <span>{user.role}</span>
                </div>
              )}
            </div>
          )}
          <ul>
            <li>
              <Link to="/" onClick={handleClick}>
                <AiOutlineHome size={24} /> Home
              </Link>
            </li>
            <li>
              <Link to="/dragdrop" onClick={handleClick}>
                <AiOutlineUser size={24} /> Product Routes
              </Link>
            </li>
            <li>
              <Link to="/userManager" onClick={handleClick}>
                <AiOutlineUser size={24} /> User Manager
              </Link>
            </li>
            <li>
              <Link to="/records" onClick={handleClick}>
                <AiOutlineDatabase size={24} /> Records
              </Link>
            </li>
            {/* <li>
              <Link to="/audit" onClick={handleClick}>
                <AiOutlineAudit size={24} /> Audit
              </Link>
            </li> */}
          </ul>
        </div>
        {user && <LogoutButton />}
      </div>
    </div>
  );
}

export default Sidebar;
