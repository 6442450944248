import React, { useState, useEffect, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/styles/FormModel.css";
import { FaCheck, FaQuestion, FaExclamation, FaTrash } from "react-icons/fa";
import { useAuth } from "../contexts/AuthContext";
import Select from "react-select";
import { SERVER_URL } from "../utils/constants";
import axios from "axios";

const Spinner = () => <div>Loading...</div>;

function AddRecordModal({ onClose }) {
  const { user, logout } = useAuth();
  const [formData, setFormData] = useState({
    processId: "",
    operatorId: user ? user.userId : "",
    productId: "",
    serial: "",
    workOrder: "",
    failDetails: "",
    result: "Pass",
    collectedSerial: "",
  });

  const [products, setProducts] = useState([]);
  const [processes, setProcesses] = useState([]);
  const [processOptions, setProcessOptions] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [itemScrapped, setItemScrapped] = useState(false);
  const [workOrderDisabled, setWorkOrderDisabled] = useState(false);
  const [processDisabled, setProcessDisabled] = useState(false);
  const [needRepaired, setNeedRepaired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const typingTimeoutRef = useRef(null); // For debouncing
  const [isMasterSerial, setIsMasterSerial] = useState(false);
  const [childSerials, setChildSerials] = useState([]);
  const [showChildSerials, setShowChildSerials] = useState(false);
  const [masterId, setMasterId] = useState(null);

  useEffect(() => {
    let newProcessOptions;
    const anyFailedProcess = processes.some(
      (process) => process.found === "true" && process.result === "fail"
    );
    const anyScrappedItem = processes.some(
      (process) => process.found === "true" && process.result === "scrap"
    );
    if (anyFailedProcess) {
      if (user.role === "Operator") {
        setDisableSubmit(anyFailedProcess);
        toast.error(
          "This record has a failed state, this must be fixed/scrapped by a debug user"
        );
      } else {
        setNeedRepaired(true);
        toast.warning(
          "The item with has a failed state. You must fix this to continue or the item should be scrapped"
        );
      }
    }
    if (anyScrappedItem) {
      setItemScrapped(true);
      toast.error(
        "This item has been scrapped you are unable to add any record against it."
      );
      newProcessOptions = processes.map((process) => ({
        value: process.process_id,
        label: (
          <>
            <FaTrash style={{ color: "red", marginRight: "5px" }} />
            {process.process}
          </>
        ),
        filterLabel: process.process,
        found: process.found,
        isDisabled: true,
      }));
    } else {
      newProcessOptions = processes.map((process) => ({
        value: process.process_id,
        label: (
          <>
            {process.found === "true" && process.result === "pass" ? (
              <FaCheck style={{ color: "green", marginRight: "5px" }} />
            ) : process.found === "true" && process.result === "fail" ? (
              <FaExclamation style={{ color: "red", marginRight: "5px" }} />
            ) : (
              <FaQuestion style={{ color: "gray", marginRight: "5px" }} />
            )}
            {process.process}
          </>
        ),
        found: process.found,
        filterLabel: process.process,
        isDisabled:
          (process.found === "true" && process.result === "pass") ||
          (process.verify_prev === "true" &&
            process.previousPassed === "false"),
      }));
    }

    setProcessOptions(newProcessOptions);
  }, [processes, user.role]);

  const productOptions = products.map((product) => ({
    value: product.product_id,
    label: product.product,
  }));

  const handleProductSelectChange = (selectedOption) => {
    // 1. Update form data with the chosen product
    handleChange({
      target: { name: "productId", value: selectedOption.value },
    });
  };

  const handleProcessChange = (selectedOption) => {
    handleChange({
      target: { name: "processId", value: selectedOption.value },
    });
  };

  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${SERVER_URL}/HC/api/getProductList.php`,
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
        }
      );
      setProducts(response.data); // With axios, you can directly use response.data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout(); // Handle 401 error by logging out the user
      }
      console.error("Error fetching products:", error);
      toast.error(
        "Error fetching products: " +
          (error.response
            ? error.response.data.message
            : "Network or server error")
      );
    } finally {
      setIsLoading(false);
    }
  }, [user.token, user.privateCode, logout]); // Dependencies

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]); // Dependency to trigger the function

  const fetchData = async () => {
    if (!formData.productId || !formData.serial) return;

    setIsLoading(true);
    try {
      // Check if it's a master serial
      const checkMasterResponse = await fetch(
        `${SERVER_URL}/HC/api/checkMasterSerial.php?serial=${formData.serial}&productId=${formData.productId}`,
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
        }
      );

      if (!checkMasterResponse.ok)
        throw new Error("Failed to fetch master serial status");
      const masterText = await checkMasterResponse.text();
      const masterSerialData = masterText ? JSON.parse(masterText) : {};

      setIsMasterSerial(masterSerialData.isMasterSerial);
      setChildSerials([]);
      let serialRef = formData.serial;

      if (masterSerialData.isMasterSerial) {
        if (
          masterSerialData.message ===
            "Not all child serials are at the same stage." ||
          masterSerialData.message ===
            "Not all child serials are at the same stage, and some may not have passed any stage yet."
        ) {
          toast.error(masterSerialData.message);
          setWorkOrderDisabled(true);
          setProcessDisabled(true);
          setDisableSubmit(true);
        } else {
          serialRef = masterSerialData.childSerials[0].serialName;
          toast.info(
            `This is a master serial. It has ${masterSerialData.childSerials.length} child serials.`
          );
        }
      } else {
        setWorkOrderDisabled(false);
        setProcessDisabled(false);
        setDisableSubmit(false);
        if (
          masterSerialData.isChildOfMaster &&
          masterSerialData.masterId &&
          masterSerialData.isInSync
        ) {
          setMasterId(masterSerialData.masterId);
          toast.warning(
            "This serial belongs to a master serial. Proceed with caution."
          );
        }
      }

      // Fetch process list
      const processResponse = await fetch(
        `${SERVER_URL}/HC/api/getProcessList.php?productId=${formData.productId}&serial=${serialRef}`,
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
        }
      );

      const processText = await processResponse.text();
      const processTextData = processText ? JSON.parse(processText) : {};

      if (
        processTextData.error ===
        "The record for the given product and serial already exists in the archive."
      ) {
        toast.error(processTextData.error);
        setWorkOrderDisabled(true);
        setProcessDisabled(true);
        setDisableSubmit(true);
      } else {
        if (!processResponse.ok)
          throw new Error("Failed to fetch process list");

        const processData = processText ? JSON.parse(processText) : [];
        setProcesses(processData);

        if (masterSerialData.isMasterSerial && masterSerialData.childSerials) {
          const updatedChildSerials = masterSerialData.childSerials.map(
            (child) => {
              const process = processes.find(
                (p) => parseInt(p.process_id) === parseInt(child.latestStage)
              );
              return {
                ...child,
                latestStageName: process ? process.process : "Unknown Process",
              };
            }
          );

          setChildSerials(updatedChildSerials);
        }

        // Fetch work order
        const workOrderResponse = await fetch(
          `${SERVER_URL}/HC/api/getWorkOrder.php?productId=${formData.productId}&serial=${serialRef}`,
          {
            headers: {
              Authorization: user.token,
              PrivateCode: user.privateCode,
            },
          }
        );

        if (workOrderResponse.ok) {
          const workOrderText = await workOrderResponse.text();
          const workOrderData = workOrderText ? JSON.parse(workOrderText) : [];
          if (workOrderData.length) {
            setFormData((prevState) => ({
              ...prevState,
              workOrder: workOrderData[0].workorder,
            }));
            setWorkOrderDisabled(true);
          }
        } else {
          setWorkOrderDisabled(false);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while fetching data.");
    } finally {
      setIsLoading(false);
    }
  };

  // Debounce serial input
  useEffect(() => {
    if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);

    typingTimeoutRef.current = setTimeout(() => {
      fetchData();
    }, 500); // Adjust debounce time as needed

    return () => clearTimeout(typingTimeoutRef.current);
  }, [formData.productId, formData.serial]); // Add other dependencies as needed

  // Debounced fetchData logic
  useEffect(() => {
    if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);

    typingTimeoutRef.current = setTimeout(() => {
      fetchData();
    }, 500); // Debounce time

    return () => clearTimeout(typingTimeoutRef.current);
  }, [formData.serial, formData.productId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedFormData = { ...formData, [name]: value };

    if (name === "productId") {
      updatedFormData = {
        ...updatedFormData,
        serial: "",
        processId: null,
        workOrder: "",
        collectedSerial: "",
      };
      updatedFormData.processId = null;
    } else if (name === "serial") {
      updatedFormData = {
        ...updatedFormData,
        processId: null,
        workOrder: "",
        collectedSerial: "",
      };
    } else if (name === "processId" && value === "") {
      updatedFormData = { ...updatedFormData, workOrder: "" };
    }

    setFormData(updatedFormData);
  };

  const clearFormData = () => {
    setFormData((prevState) => ({
      ...prevState,
      serial: "",
      workOrder: "",
      processId: null,
      failDetails: "",
      collectedSerial: "",
    }));
    setNeedRepaired(false);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (disableSubmit) {
      toast.error(
        "Submit button is disabled. Please check the form for any errors."
      );
      return;
    }

    // 1) Find the user-selected process (so we can get the name for debugging or child-serial)
    const selectedProcessOption = processOptions.find(
      (opt) => opt.value === formData.processId
    );
    const processName = selectedProcessOption
      ? selectedProcessOption.filterLabel // e.g. "Fit Processor PCBA"
      : "";

    // 2) Build your normal submission data
    const submissionData = {
      ...formData,
      // If you have a separate property for "processName", you can set it here:
      processName,
      ...(masterId && { masterId }), // Spread operator to conditionally add masterId
    };

    // 3) If this stage requires collecting a serial, append the top-level logic:
    //    (Meaning, we pass "TopLevelSerialName" and "processSerials" to unify with server code.)
    const selectedProcess = processes.find(
      (p) => String(p.process_id) === String(formData.processId)
    );
    const shouldCollectSerial = selectedProcess?.collect_serial === "true";

    if (shouldCollectSerial && formData.collectedSerial) {
      submissionData.TopLevelSerialName = formData.serial;
      submissionData.processSerials = [
        {
          processName,
          collectedSerial: formData.collectedSerial,
        },
      ];
    }

    try {
      // 4) Send it all in ONE POST request to addData.php
      const response = await fetch(`${SERVER_URL}/hc/api/addData.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: user.token,
          PrivateCode: user.privateCode,
        },
        body: JSON.stringify(submissionData),
      });

      const data = await response.json();

      if (data.status === "success") {
        toast.success(data.message || "Record added successfully.");
      } else if (data.message) {
        toast.error(data.message);
      } else {
        toast.error("Unexpected Error");
      }
    } catch (error) {
      console.error(error);
      toast.error("Network or server error occurred.");
    }

    // 5) Clear the form after success/failure
    clearFormData();
  };

  const selectedProcess = processes.find(
    (p) => String(p.process_id) === String(formData.processId)
  );
  const shouldCollectSerial = selectedProcess?.collect_serial === "true";

  return (
    <div className="form-modal">
      {isLoading && <Spinner />} {/* Render spinner when loading */}
      {
        <div className="form-modal">
          <div className="form-modal-content">
            <h2>Add New Record</h2>
            <form onSubmit={onSubmit}>
              <label>
                Product:
                <Select
                  id="productSelect"
                  className="product-select"
                  name="productId"
                  value={productOptions.find(
                    (option) => option.value === formData.productId
                  )}
                  onChange={handleProductSelectChange}
                  options={productOptions}
                  classNamePrefix="react-select"
                />
              </label>
              <br />
              <label className={!formData.productId ? "disabled-label" : ""}>
                Serial Number:
                <input
                  type="text"
                  name="serial"
                  value={formData.serial}
                  onChange={handleChange}
                  disabled={!formData.productId}
                  className={`${!formData.productId ? "disabled-select" : ""} ${
                    isMasterSerial ? "master-serial-input" : ""
                  }`}
                />
              </label>

              {isMasterSerial && (
                <div className="master-serial-toggle">
                  <button
                    type="button" // This prevents the form from being submitted
                    onClick={() => setShowChildSerials(!showChildSerials)}
                    className="toggle-children-btn show-children-button"
                  >
                    {showChildSerials
                      ? "Hide Children"
                      : `Show Children (${childSerials.length})`}
                  </button>
                  {showChildSerials && (
                    <div className="master-serial-info">
                      <h4>Master Serial Information</h4>
                      <p>
                        This serial is a master serial with the following child
                        serials:
                      </p>
                      {/* Determine unique stages only once to improve performance */}
                      {(() => {
                        const uniqueStages = [
                          ...new Set(
                            childSerials.map((item) => item.latestStageName)
                          ),
                        ];
                        return (
                          <>
                            {uniqueStages.length > 1 && (
                              <p style={{ color: "red" }}>
                                Error: Child serials are at different stages.
                              </p>
                            )}
                            <ul>
                              {childSerials.map((child, index) => {
                                let color = "green"; // Default color when only one unique stage
                                if (uniqueStages.length > 1) {
                                  // Cycling through a predefined set of colors for simplicity
                                  const colors = [
                                    "red",
                                    "purple",
                                    "blue",
                                    "orange",
                                  ]; // Add more colors as needed
                                  const stageIndex = uniqueStages.indexOf(
                                    child.latestStageName
                                  );
                                  color = colors[stageIndex % colors.length];
                                }
                                return (
                                  <li key={child.serialId} style={{ color }}>
                                    {child.serialName} - {child.latestStageName}
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        );
                      })()}
                    </div>
                  )}
                </div>
              )}
              <br />
              <label
                className={
                  !formData.serial || !formData.productId || workOrderDisabled
                    ? "disabled-label"
                    : ""
                }
              >
                Work Order:
                <input
                  type="text"
                  name="workOrder"
                  value={formData.workOrder}
                  onChange={handleChange}
                  disabled={
                    !formData.serial || !formData.productId || workOrderDisabled
                  }
                  className={
                    !formData.serial || !formData.productId || workOrderDisabled
                      ? "disabled-select"
                      : ""
                  }
                />
              </label>
              <br />
              <label
                className={
                  !formData.serial || !formData.productId
                    ? "disabled-label"
                    : ""
                }
              >
                Process:
                <Select
                  id="processSelect"
                  name="processId"
                  value={
                    formData.processId
                      ? processOptions.find(
                          (option) => option.value === formData.processId
                        )
                      : null
                  }
                  onChange={handleProcessChange}
                  options={processOptions}
                  filterOption={(option, inputValue) =>
                    option.data.filterLabel
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  isDisabled={
                    !formData.serial || !formData.productId || processDisabled
                  }
                  className={
                    !formData.serial || !formData.productId || processDisabled
                      ? "disabled-select process-select"
                      : "process-select"
                  }
                  classNamePrefix="react-select"
                  isClearable={true}
                />
              </label>
              <br />

              {/* 
            NEW: Show a "Collect Serial" text input 
            if the selected process has collect_serial === "true"
          */}
              {shouldCollectSerial && (
                <div style={{ marginBottom: "1rem" }}>
                  <label>
                    Collect Serial (for this Process):
                    <input
                      type="text"
                      name="collectedSerial"
                      value={formData.collectedSerial}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              )}

              <br />

              <label
                className={
                  !formData.processId ||
                  !formData.serial ||
                  !formData.productId ||
                  !formData.workOrder
                    ? "disabled-label radio-label"
                    : "radio-label"
                }
              >
                Result:
                {needRepaired ? (
                  <>
                    <input
                      type="radio"
                      name="result"
                      value="Resolved"
                      checked={formData.result === "Resolved"}
                      onChange={handleChange}
                      disabled={
                        !formData.processId ||
                        !formData.serial ||
                        !formData.productId ||
                        !formData.workOrder ||
                        itemScrapped ||
                        (disableSubmit && user.role === "Operator")
                      }
                    />
                    Resolved
                    <input
                      type="radio"
                      name="result"
                      value="Scrap"
                      checked={formData.result === "Scrap"}
                      onChange={handleChange}
                      disabled={
                        !formData.processId ||
                        !formData.serial ||
                        !formData.productId ||
                        !formData.workOrder ||
                        itemScrapped ||
                        (disableSubmit && user.role === "Operator")
                      }
                    />
                    Scrap
                  </>
                ) : (
                  <>
                    <input
                      type="radio"
                      name="result"
                      value="Pass"
                      checked={formData.result === "Pass"}
                      onChange={handleChange}
                      disabled={
                        !formData.processId ||
                        !formData.serial ||
                        !formData.productId ||
                        itemScrapped ||
                        (disableSubmit && user.role === "Operator")
                      }
                    />
                    Pass
                    <input
                      type="radio"
                      name="result"
                      value="Fail"
                      checked={formData.result === "Fail"}
                      onChange={handleChange}
                      disabled={
                        !formData.processId ||
                        !formData.serial ||
                        !formData.productId ||
                        itemScrapped ||
                        (disableSubmit && user.role === "Operator")
                      }
                    />
                    Fail
                    {disableSubmit && user.role !== "Operator" ? (
                      <>
                        <input
                          type="radio"
                          name="result"
                          value="Scrap"
                          checked={formData.result === "Scrap"}
                          onChange={handleChange}
                          disabled={
                            !formData.processId ||
                            !formData.serial ||
                            !formData.productId ||
                            itemScrapped ||
                            (disableSubmit && user.role === "Operator")
                          }
                        />
                        Scrap
                      </>
                    ) : null}
                  </>
                )}
              </label>
              <br />
              {formData.result === "Fail" || needRepaired ? (
                <label>
                  Details:
                  <textarea
                    name="failDetails"
                    value={formData.failDetails}
                    onChange={handleChange}
                  />
                </label>
              ) : null}
              <br />
              <button className="close-button" type="button" onClick={onClose}>
                X
              </button>
              <button
                className="add-button"
                type="submit"
                disabled={
                  !formData.processId ||
                  !formData.serial ||
                  !formData.productId ||
                  disableSubmit ||
                  itemScrapped ||
                  (disableSubmit && user.role === "Operator")
                }
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      }
    </div>
  );
}

export default AddRecordModal;
