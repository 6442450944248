import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/Home";
import ProductRoutes from "./pages/ProductRoutes";
import UserManager from "./pages/UserManager";
import Sidebar from "./components/Sidebar";
import "./App.css";
import useUnsavedChangesAlert from "./hooks/useUnsavedChangesAlert";
import LoginPage from "./pages/LoginPage";
import RestrictedPage from "./pages/RestrictedPage";
import ReportsPage from "./pages/ReportsPage";
import Records from "./pages/Records";
import Audit from "./pages/Audit";
import Dashboard from "./pages/Dashboard";
import MasterSerials from "./pages/MasterSerials";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateLayout from "./components/PrivateLayout";

function AppContent({ isSidebarShown, toggleSidebar }) {
  const navigate = useNavigate();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { showAlert } = useUnsavedChangesAlert(
    hasUnsavedChanges,
    setHasUnsavedChanges
  );

  const handleLinkClick = (e) => {
    if (hasUnsavedChanges) {
      e.preventDefault();
      const href = e.target.getAttribute("href");
      const basePath = "/build";
      const relativePath = href.replace(basePath, ""); // Remove the base path

      showAlert((proceed) => {
        if (proceed) {
          navigate(relativePath);
        }
      });
    }
  };

  return (
    <div className="App">
      <Sidebar
        hasUnsavedChanges={hasUnsavedChanges}
        handleLinkClick={handleLinkClick}
        isShown={isSidebarShown}
        toggleSidebar={toggleSidebar}
      />
      <div
        className={`content ${
          isSidebarShown ? "sidebar-active" : "sidebar-inactive"
        }`}
      >
        <div
          className={`content content-area ${
            isSidebarShown ? "sidebar-active" : ""
          }`}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/restrictedPage" element={<RestrictedPage />} />
            <Route path="/*" element={<PrivateLayout isAdminPage={true} />}>
              <Route
                path="dragdrop"
                element={
                  <ProductRoutes
                    hasUnsavedChanges={hasUnsavedChanges}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    handleLinkClick={handleLinkClick}
                  />
                }
              />
              <Route
                path="userManager"
                element={
                  <UserManager
                    hasUnsavedChanges={hasUnsavedChanges}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />
                }
              />
              <Route
                path="masterSerials"
                element={
                  <MasterSerials
                    hasUnsavedChanges={hasUnsavedChanges}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />
                }
              />
              <Route path="audit" element={<Audit />} />
            </Route>
            <Route path="reports" element={<ReportsPage />} />
            <Route path="/*" element={<PrivateLayout isAdminPage={false} />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="records" element={<Records />} />
            </Route>
          </Routes>
        </div>
      </div>
    </div>
  );
}

function App() {
  const [isSidebarShown, setIsSidebarShown] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarShown(!isSidebarShown);
  };

  return (
    <Router basename="/build">
      <AuthProvider>
        <AppContent
          isSidebarShown={isSidebarShown}
          toggleSidebar={toggleSidebar}
        />
      </AuthProvider>
    </Router>
  );
}

export default App;
