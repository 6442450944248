import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { SERVER_URL } from "../utils/constants";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "../App.css";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import AddMasterSerialModal from "../models/AddMasterSerialModal";
import useAlert from "../hooks/useAlert";

const MasterSerials = ({ hasUnsavedChanges, setHasUnsavedChanges }) => {
  const [masterSerials, setMasterSerials] = useState([]);
  const [selectedMasterSerial, setSelectedMasterSerial] = useState("");
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const product = location.state?.product;
  const [isAddMasterSerialModalOpen, setIsAddMasterSerialModalOpen] =
    useState(false);
  const [inputSerial, setInputSerial] = useState("");
  const [existingChildSerials, setExistingChildSerials] = useState([]);
  const [newChildSerials, setNewChildSerials] = useState([]);
  const [allChildSerials, setAllChildSerials] = useState([]);
  const showAlert = useAlert();

  const handleDeleteMasterSerial = () => {
    showAlert(
      true, // hasCondition is always true to show the dialog
      "Confirm Delete",
      `Are you sure you want to delete this master serial?`,
      (confirmed) => {
        if (confirmed) {
          deleteMasterSerial(); // Proceed with delete operation
        }
      },
      "confirm" // alertType
    );
  };

  const deleteMasterSerial = async () => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/HC/api/deleteMasterSerial.php`,
        {
          data: {
            ProductId: product.product_id,
            MasterSerial: selectedMasterSerial,
          },
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
        }
      );

      if (response.data.success) {
        toast.success("Master serial deleted successfully");
        fetchMasterSerials(); // Refresh the master serials list after deletion
      } else {
        toast.error("Failed to delete master serial");
      }
    } catch (error) {
      toast.error("Error deleting master serial");
    }
  };

  const fetchMasterSerials = useCallback(
    async (selectedMasterSerialId = null) => {
      if (!product || !product.product_id) return;

      try {
        const response = await axios.get(
          `${SERVER_URL}/HC/api/getMasterSerials.php`,
          {
            params: { ProductId: product.product_id },
            headers: {
              Authorization: user.token,
              PrivateCode: user.privateCode,
            },
          }
        );

        if (Array.isArray(response.data)) {
          setMasterSerials(response.data);

          // Determine which master serial to select
          const masterSerialToSelect =
            selectedMasterSerialId &&
            response.data.some(
              (masterSerial) =>
                masterSerial.MasterSerialId.toString() ===
                selectedMasterSerialId
            )
              ? selectedMasterSerialId
              : response.data[0]?.MasterSerialId.toString();

          setSelectedMasterSerial(masterSerialToSelect);

          // Update child serials for the selected master serial
          const childSerialsForSelectedMaster =
            response.data.find(
              (masterSerial) =>
                masterSerial.MasterSerialId.toString() === masterSerialToSelect
            )?.ChildSerials || [];
          setExistingChildSerials(childSerialsForSelectedMaster);

          // Update all child serials list
          const allChildSerials = response.data.flatMap(
            (masterSerial) => masterSerial.ChildSerials || []
          );
          setAllChildSerials(allChildSerials);
        } else {
          console.error("Unexpected response structure:", response.data);
          setMasterSerials([]);
          setExistingChildSerials([]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          logout();
        }
        console.error("Error fetching master serials:", error);
      }
    },
    [product, user.token, user.privateCode, logout]
  );

  // Adjusted useEffect for initial load and refresh after publishing
  useEffect(() => {
    if (!product) {
      showAlert(
        true, // Always show this alert because there's no condition
        "Missing Product",
        "Product information is missing. You will be redirected.",
        () => navigate(-1), // Go back if user acknowledges the alert
        "alert" // This is a simple alert, not a confirmation dialog
      );
    } else {
      fetchMasterSerials();
    }
  }, [fetchMasterSerials, navigate, product, showAlert]);

  //fix this useeffect the master.MasterSerialId seems to be null
  useEffect(() => {
    // Ensure masterSerials is treated as an array
    const serialsArray = Array.isArray(masterSerials) ? masterSerials : [];

    // Find the master serial object based on the selectedMasterSerial state
    const selectedMaster = serialsArray.find(
      (master) => master.MasterSerialId.toString() === selectedMasterSerial
    );

    // If a selected master serial is found, update existingChildSerials with its child serials
    if (selectedMaster && selectedMaster.ChildSerials) {
      setExistingChildSerials(selectedMaster.ChildSerials);
    } else {
      // If no master serial is selected or found, clear the existingChildSerials
      setExistingChildSerials([]);
    }
  }, [selectedMasterSerial, masterSerials]);

  const handleAddSerialToList = () => {
    const inputSerialLower = inputSerial.toLowerCase(); // Convert input serial to lowercase for case-insensitive comparison

    const rangeRegex = /\{(\d+)-(\d+):?(\d+)?\}/;
    const match = inputSerial.match(rangeRegex);
    const tempNewChildSerials = [];
    if (match) {
      const [fullPlaceholder, start, end, increment = "1"] = match;
      const startNum = parseInt(start, 10);
      const endNum = parseInt(end, 10);
      const inc = parseInt(increment, 10);

      for (let i = startNum; i <= endNum; i += inc) {
        const serial = inputSerial
          .replace(fullPlaceholder, i.toString())
          .toLowerCase();

        if (
          existingChildSerials.length +
            newChildSerials.length +
            tempNewChildSerials.length >=
          100
        ) {
          toast.error("Cannot add more than 100 child serials.");
          return; // Prevent adding more serials if limit is reached
        }

        tempNewChildSerials.push({ serial });

        // Check if the serial is in existingChildSerials and marked for removal
        const existingIndex = existingChildSerials.findIndex(
          (existingSerial) =>
            existingSerial.serial.toLowerCase() === serial &&
            existingSerial.toBeRemoved
        );
        if (existingIndex !== -1) {
          existingChildSerials[existingIndex].toBeRemoved = false;
          setExistingChildSerials([...existingChildSerials]);
        } else if (!serialExists(serial)) {
          // Add new serial if it doesn't exist and is not marked for removal
          setNewChildSerials((prev) => [...prev, { serial }]);
        }
      }
    } else {
      if (
        existingChildSerials.length +
          newChildSerials.length +
          tempNewChildSerials.length >=
        100
      ) {
        toast.error("Cannot add more than 100 child serials.");
        return; // Prevent adding more serials if limit is reached
      }

      // Similar logic for a single serial
      const existingIndex = existingChildSerials.findIndex(
        (existingSerial) =>
          existingSerial.serial.toLowerCase() === inputSerialLower &&
          existingSerial.toBeRemoved
      );
      if (existingIndex !== -1) {
        // Unmark for removal if it's already in the existingChildSerials
        existingChildSerials[existingIndex].toBeRemoved = false;
        setExistingChildSerials([...existingChildSerials]);
      } else if (!serialExists(inputSerialLower)) {
        // Add as new if it's not existing and not marked for removal
        setNewChildSerials((prev) => [...prev, { serial: inputSerialLower }]);
      }
    }

    setInputSerial(""); // Clear input field after adding

    // Function to check if serial exists in any list, adjusting error messages as needed
    function serialExists(serial) {
      const serialInNewChildSerials = newChildSerials.some(
        (e) => e.serial.toLowerCase().trim() === serial.toLowerCase().trim()
      );

      // Checking if a serial is in existingChildSerials without the toBeRemoved flag or not present at all
      const serialInExistingChildSerials = existingChildSerials.some(
        (e) =>
          e.serial.toLowerCase().trim() === serial.toLowerCase().trim() &&
          !e.toBeRemoved
      );

      // Re-use this condition to check against allChildSerials, considering the serial could exist in a different master
      const serialInAllChildSerialsNotMarkedForRemoval = allChildSerials.some(
        (e) =>
          e.serial.toLowerCase().trim() === serial.toLowerCase().trim() &&
          !e.toBeRemoved
      );

      const serialInMasterSerials = masterSerials.some(
        (masterSerial) =>
          masterSerial.serial?.toLowerCase().trim() ===
          serial.trim().toLowerCase()
      );

      if (serialInNewChildSerials || serialInExistingChildSerials) {
        toast.error(
          `This serial is already added to the current master serial: ${serial}`
        );
        return true;
      } else if (
        serialInAllChildSerialsNotMarkedForRemoval &&
        !serialInExistingChildSerials
      ) {
        toast.error(
          `Cannot add a serial to the child serial list which is already a child serial for another master: ${serial}`
        );
        return true;
      } else if (serialInMasterSerials) {
        toast.error(
          `Cannot add a serial to the child serial list which is already a master serial for this product: ${serial}`
        );
        return true;
      }
      return false;
    }
  };

  useEffect(() => {
    // If newChildSerials is not empty, consider it as having unsaved changes
    setHasUnsavedChanges(newChildSerials.length > 0);
  }, [newChildSerials, setHasUnsavedChanges]);

  useEffect(() => {
    // Redirect or show an error if product is not available
    if (!product) {
      toast.error("Product information is missing.");
      navigate(-1); // Go back to the previous page or navigate to a default page
      return;
    }

    fetchMasterSerials();
  }, [fetchMasterSerials, navigate, product]);

  const handleAddMasterSerialSubmit = async (masterSerialName) => {
    // Ensure there's a selected product before proceeding
    if (!product || !product.product_id) {
      toast.error("No product selected.");
      return;
    }

    try {
      const response = await axios.post(
        `${SERVER_URL}/HC/api/addMasterSerial.php`,
        {
          ProductId: product.product_id, // Use the selected product's ID
          MasterSerial: masterSerialName, // Use the master serial name from the modal
        },
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
        }
      );

      // Check the response for success
      if (response.data.success) {
        toast.success("Master serial added successfully!");
        setMasterSerials(""); // Clear any existing state if necessary
        fetchMasterSerials(); // Refresh the list of master serials
        setIsAddMasterSerialModalOpen(false);
      } else {
        // If the API response includes a message, display it; otherwise, show a default error message
        toast.error(response.data.message || "Failed to add master serial.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
      }
      // Handle errors from the API call
      setIsAddMasterSerialModalOpen(false);
      if (error.response) {
        // If the error response includes a specific message, display it
        toast.error(
          error.response.data.message ||
            "An error occurred while adding the master serial."
        );
      } else {
        // For network errors or other issues not caught by the response
        toast.error("An error occurred while adding the master serial.");
      }
      console.error("There was an error adding the master serial:", error);
    }
  };

  // Adjust handleSubmitAllNewSerials to refresh child serials correctly after publishing
  const handleSubmitAllNewSerials = async () => {
    if (!product || !product.product_id) {
      toast.error("No product selected.");
      return;
    }

    const payload = {
      MasterSerialId: selectedMasterSerial,
      addSerials: newChildSerials.map((serial) => serial.serial),
      removeSerials: existingChildSerials
        .filter((serial) => serial.toBeRemoved)
        .map((serial) => serial.SerialId),
    };

    try {
      const response = await axios.post(
        `${SERVER_URL}/HC/api/manageChildSerials.php`,
        payload,
        {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);

        // Process added serials assuming they now include SerialId along with the name
        // Example addedSerials format: [{ serial: "hc-ab4-cde", SerialId: 1300 }]
        const addedSerials = response.data.addedSerials || [];

        // Process removed serials
        const removedSerialIds = new Set(response.data.removedSerials || []);

        // Update existingChildSerials
        let updatedExistingChildSerials = existingChildSerials
          .filter((serial) => !removedSerialIds.has(serial.SerialId))
          .concat(
            addedSerials.map((serial) => ({
              serial: serial.serial, // Assuming your state tracks this as 'serial'
              SerialId: serial.SerialId, // Make sure this matches how your state represents SerialId
            }))
          );

        setExistingChildSerials(updatedExistingChildSerials);

        // Clear newChildSerials after successful addition
        setNewChildSerials([]);

        // Optionally, update allChildSerials or refetch master serials to ensure synchronization
        fetchMasterSerials();
      } else {
        toast.error(
          response.data.message || "Failed to manage child serial(s)."
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
      }
      console.error("Error managing child serial(s):", error);
      toast.error("Failed to manage child serial(s).");
    }
  };

  const handleRemoveSerial = (index, serialId) => {
    if (serialId) {
      // Marking an existing child serial to be removed by setting a `toBeRemoved` flag
      const updatedExistingSerials = existingChildSerials.map((item, idx) =>
        idx === index ? { ...item, toBeRemoved: true } : item
      );
      setExistingChildSerials(updatedExistingSerials);
    } else {
      // Removing a newly added serial from the newChildSerials list
      const updatedNewSerials = newChildSerials.filter(
        (_, idx) => idx !== index
      );
      setNewChildSerials(updatedNewSerials);
    }
  };

  return (
    <div>
      <h2>Master Serials : {product.product}</h2>
      {product && product.product_id && (
        <>
          <div>
            <label>Master Serial:</label>
            <select
              value={selectedMasterSerial}
              onChange={(e) => {
                const nextValue = e.target.value; // Capture the next value here
                if (newChildSerials.length > 0) {
                  showAlert(
                    true, // Condition to show alert
                    "Unsaved Changes", // Alert title
                    "You have unsaved child serials. If you change the master serial, these will be lost. Do you want to continue?", // Alert message
                    (proceed) => {
                      if (proceed) {
                        // Filter out the newChildSerials from allChildSerials if user chose to proceed
                        const updatedAllChildSerials = allChildSerials.filter(
                          (childSerial) =>
                            !newChildSerials.some(
                              (newSerial) =>
                                newSerial.serial === childSerial.serial
                            )
                        );
                        setAllChildSerials(updatedAllChildSerials);
                        setNewChildSerials([]);
                        setSelectedMasterSerial(nextValue); // Use captured value
                      }
                      // If the user does not proceed, we don't need to reset the select's value,
                      // since not changing the state will automatically retain the previous selection.
                    },
                    "confirm" // Alert type
                  );
                } else {
                  // No unsaved changes, just proceed with the change
                  setSelectedMasterSerial(nextValue); // Use captured value
                }
              }}
            >
              <option value="">Select a Master Serial</option>
              {Array.isArray(masterSerials) &&
                masterSerials.map((serial) => (
                  <option
                    key={serial.MasterSerialId}
                    value={serial.MasterSerialId}
                  >
                    {serial.MasterSerialName}
                  </option>
                ))}
            </select>
            <button
              className="button"
              onClick={() => setIsAddMasterSerialModalOpen(true)}
            >
              Add New Master Serial
            </button>
            <AddMasterSerialModal
              isOpen={isAddMasterSerialModalOpen}
              onRequestClose={() => setIsAddMasterSerialModalOpen(false)}
              onSubmit={handleAddMasterSerialSubmit}
            />
          </div>
          {selectedMasterSerial && (
            <>
              <div>
                <button
                  className="button remove-button"
                  onClick={handleDeleteMasterSerial}
                >
                  Delete Master Serial
                </button>
                <h3>Child Serials:</h3>
                <input
                  type="text"
                  placeholder="Enter serial or range (e.g., HC-AB{1-5:1}-2024)"
                  className="serial-input"
                  value={inputSerial}
                  onChange={(e) => setInputSerial(e.target.value)}
                />
                <button className="button" onClick={handleAddSerialToList}>
                  Add to List
                </button>

                <div className="serial-list-container">
                  {[
                    ...existingChildSerials.filter((item) => !item.toBeRemoved),
                    ...newChildSerials,
                  ].map((item, index) => (
                    <div
                      key={item.SerialId || item.serial || index} // Ensure a unique key, prefer SerialId or serial over index when possible
                      className="serial-list-item"
                    >
                      {item.serial}{" "}
                      <button
                        className="button remove-button"
                        onClick={() => handleRemoveSerial(index, item.SerialId)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
                <button
                  class="button publish-button"
                  onClick={handleSubmitAllNewSerials}
                >
                  Publish
                </button>
              </div>
            </>
          )}
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default MasterSerials;
