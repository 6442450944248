import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import SummaryCards from "../components/SummaryCards";
import OperatorsSummary from "../components/OperatorsSummary";
import FailDetails from "../components/FailDetails";
import ResolveDetails from "../components/ResolveDetails";
import SerialTracker from "../components/SerialTracker";
import WorkOrderOverviewTable from "../components/WorkOrderOverviewTable";
import { Space, Spin } from "antd";
import { SERVER_URL } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import "../assets/styles/WorkOrderOverview.css";

const Home = () => {
  const [summaryData, setSummaryData] = useState({});
  const [operatorsData, setOperatorsData] = useState([]);
  const [failData, setFailData] = useState([]);
  const [resolveData, setResolveData] = useState([]);
  const [serialsData, setSerialsData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [workOrderDetails, setWorkOrderDetails] = useState({});
  const { user, logout } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSummaryData = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/HC/api/summary.php`, {
          headers: {
            Authorization: user.token,
            PrivateCode: user.privateCode,
          },
        });
        setSummaryData(response.data);
      } catch (error) {
        console.error("Error fetching summary data", error);
        if (error.response && error.response.status === 401) {
          logout();
        }
      }
    };

    if (user) {
      fetchSummaryData();
    }
  }, [user, logout]);

  useEffect(() => {
    const fetchWorkOrderOverview = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${SERVER_URL}/HC/api/work_order_overview.php`,
          {
            headers: {
              Authorization: user.token,
              PrivateCode: user.privateCode,
            },
          }
        );
        if (response.data && response.data.records) {
          setReportData(response.data.records);
        } else {
          setReportData([]);
        }
      } catch (error) {
        console.error("Error fetching work order overview", error);
        setReportData([]);
        if (error.response && error.response.status === 401) {
          logout();
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchWorkOrderOverview();
    }
  }, [user, logout]);

  useEffect(() => {
    const fetchOperatorsData = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/HC/api/operators_summary.php`,
          {
            headers: {
              Authorization: user.token,
              PrivateCode: user.privateCode,
            },
          }
        );
        setOperatorsData(response.data);
      } catch (error) {
        console.error("Error fetching operators data", error);
        if (error.response && error.response.status === 401) {
          logout();
        }
      }
    };

    if (user) {
      fetchOperatorsData();
    }
  }, [user, logout]);

  useEffect(() => {
    const fetchFailData = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/HC/api/fail_details.php`,
          {
            headers: {
              Authorization: user.token,
              PrivateCode: user.privateCode,
            },
          }
        );
        setFailData(response.data);
      } catch (error) {
        console.error("Error fetching fail data", error);
        if (error.response && error.response.status === 401) {
          logout();
        }
      }
    };

    if (user) {
      fetchFailData();
    }
  }, [user, logout]);

  useEffect(() => {
    const fetchResolveData = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/HC/api/resolve_details.php`,
          {
            headers: {
              Authorization: user.token,
              PrivateCode: user.privateCode,
            },
          }
        );
        setResolveData(response.data);
      } catch (error) {
        console.error("Error fetching resolve data", error);
        if (error.response && error.response.status === 401) {
          logout();
        }
      }
    };

    if (user) {
      fetchResolveData();
    }
  }, [user, logout]);

  useEffect(() => {
    const fetchSerialsData = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/HC/api/serial_tracker.php`,
          {
            headers: {
              Authorization: user.token,
              PrivateCode: user.privateCode,
            },
          }
        );
        setSerialsData(response.data);
      } catch (error) {
        console.error("Error fetching serials data", error);
        if (error.response && error.response.status === 401) {
          logout();
        }
      }
    };

    if (user) {
      fetchSerialsData();
    }
  }, [user, logout]);

  if (!user) {
    navigate("/login");
    return null;
  }
  if (isLoading && user) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="work-order-overview">
      <h1 className="page-title">Dashboard</h1>
      <SummaryCards {...summaryData} />
      <Space style={{ marginBottom: 16 }} />
      <WorkOrderOverviewTable
        reportData={reportData}
        workOrderDetails={workOrderDetails}
        setWorkOrderDetails={setWorkOrderDetails}
      />
      <SerialTracker serialsData={serialsData} />
      <OperatorsSummary operatorsData={operatorsData} />
      <FailDetails failData={failData} />
      <ResolveDetails resolveData={resolveData} />
    </div>
  );
};

export default Home;
