import React, { useState } from "react";
import Modal from "react-modal";
import "../assets/styles/FormModel.css"; // Import the CSS

Modal.setAppElement("#root");

const AddMasterSerialModal = ({ isOpen, onRequestClose, onSubmit }) => {
  const [masterSerialName, setMasterSerialName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!masterSerialName.trim()) {
      alert("Please enter a master serial name.");
      return;
    }

    onSubmit(masterSerialName);
    setMasterSerialName("");
  };

  return (
    <div className={isOpen ? "form-modal" : ""}>
      {isOpen && (
        <div className="form-modal-content">
          <h2>Add New Master Serial</h2>
          <button className="close-button" onClick={onRequestClose}>
            &times;
          </button>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="master-serial-name">Master Serial Name:</label>
              <input
                type="text"
                id="master-serial-name"
                className="form-control"
                value={masterSerialName}
                onChange={(e) => setMasterSerialName(e.target.value)}
              />
            </div>
            <button type="submit" className="add-button">
              Add Master Serial
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddMasterSerialModal;
