import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";

const PublishSection = ({ submitData }) => {
  return (
    <div className="add-section">
      <button class="button publish-button" onClick={submitData}>
        <AiOutlineCloudUpload className="icon" />
        Publish
      </button>
    </div>
  );
};

export default PublishSection;
