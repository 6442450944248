import React, { useState, useEffect } from "react";
import { Table, Input, Button, Space, DatePicker } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  ProfileOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(isBetween);
dayjs.extend(relativeTime);

const { RangePicker } = DatePicker;

const OperatorsSummary = ({ operatorsData }) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30", "50", "100"],
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [filteredData, setFilteredData] = useState(operatorsData);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const filtered = operatorsData.filter((record) => {
        const recordDate = dayjs(record.lastEntryTime);
        return recordDate.isBetween(dateRange[0], dateRange[1], null, "[]");
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(operatorsData);
    }
  }, [dateRange, operatorsData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const columns = [
    {
      title: "Operator",
      dataIndex: "operatorName",
      key: "operatorName",
      ...getColumnSearchProps("operatorName"),
    },
    {
      title: "Total Pass",
      dataIndex: "totalPass",
      key: "totalPass",
      sorter: (a, b) => a.totalPass - b.totalPass,
      render: (text) => (
        <span>
          <CheckCircleOutlined style={{ color: "green" }} /> {text}
        </span>
      ),
    },
    {
      title: "Total Fail",
      dataIndex: "totalFail",
      key: "totalFail",
      sorter: (a, b) => a.totalFail - b.totalFail,
      render: (text) => (
        <span>
          <CloseCircleOutlined style={{ color: "red" }} /> {text}
        </span>
      ),
    },
    {
      title: "Last Work Order",
      dataIndex: "lastWorkOrder",
      key: "lastWorkOrder",
      ...getColumnSearchProps("lastWorkOrder"),
      render: (text) => (
        <span>
          <ProfileOutlined style={{ color: "#1890ff" }} /> {text}
        </span>
      ),
    },
    {
      title: "Last Entry Time",
      dataIndex: "lastEntryTime",
      key: "lastEntryTime",
      sorter: (a, b) =>
        dayjs(a.lastEntryTime).unix() - dayjs(b.lastEntryTime).unix(),
      render: (text) => (
        <span>
          <ClockCircleOutlined style={{ color: "#fa541c" }} />{" "}
          {dayjs(text).fromNow()}
        </span>
      ),
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            onChange={(dates) => {
              handleDateRangeChange(dates);
              confirm();
            }}
          />
        </div>
      ),
      onFilter: (value, record) => {
        if (!dateRange[0] || !dateRange[1]) return true;
        const startDate = dayjs(dateRange[0]);
        const endDate = dayjs(dateRange[1]);
        return dayjs(record.lastEntryTime).isBetween(
          startDate,
          endDate,
          null,
          "[]"
        );
      },
    },
  ];

  return (
    <div className="operators-summary">
      <h2>Operators Summary</h2>
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey="operatorName"
        pagination={pagination}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default OperatorsSummary;
